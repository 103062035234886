
/*
 * A JavaScript implementation of the RSA Data Security, Inc. MD5 Message
 * Digest Algorithm, as defined in RFC 1321.
 * Version 2.2 Copyright (C) Paul Johnston 1999 - 2009
 * Other contributors: Greg Holt, Andrew Kepert, Ydnar, Lostinet
 * Distributed under the BSD License
 * See http://pajhome.org.uk/crypt/md5 for more info.
 */


/* global $ */
import {onfiledrop} from './onfiledrop';
import axios from 'axios'; 
import {checkSub} from './utils';
 
// import uuid from "uuid";

 
/*
 * Configurable variables. You may need to tweak these to be compatible with
 * the server-side, but the defaults work in most cases.
 */
var hexcase = 0;   /* hex output format. 0 - lowercase; 1 - uppercase        */
var b64pad  = "=";  /* base-64 pad character. "=" for strict RFC compliance   */



/*
 * These are the functions you'll usually want to call
 * They take string arguments and return either hex or base-64 encoded strings
 */
function hex_md5(s)    { return rstr2hex(rstr_md5(str2rstr_utf8(s))); }
function b64_md5(s)    { return rstr2b64(rstr_md5(str2rstr_utf8(s))); }
function any_md5(s, e) { return rstr2any(rstr_md5(str2rstr_utf8(s)), e); }
function hex_hmac_md5(k, d)
  { return rstr2hex(rstr_hmac_md5(str2rstr_utf8(k), str2rstr_utf8(d))); }
function b64_hmac_md5(k, d)
  { return rstr2b64(rstr_hmac_md5(str2rstr_utf8(k), str2rstr_utf8(d))); }
function any_hmac_md5(k, d, e)
  { return rstr2any(rstr_hmac_md5(str2rstr_utf8(k), str2rstr_utf8(d)), e); }

/*
 * Perform a simple self-test to see if the VM is working
 */
function md5_vm_test()
{
  return hex_md5("abc").toLowerCase() == "900150983cd24fb0d6963f7d28e17f72";
}

/*
 * Calculate the MD5 of a raw string
 */
function rstr_md5(s)
{
  return binl2rstr(binl_md5(rstr2binl(s), s.length * 8));
}

/*
 * Calculate the HMAC-MD5, of a key and some data (raw strings)
 */
function rstr_hmac_md5(key, data)
{
  var bkey = rstr2binl(key);
  if(bkey.length > 16) bkey = binl_md5(bkey, key.length * 8);

  var ipad = Array(16), opad = Array(16);
  for(var i = 0; i < 16; i++)
  {
    ipad[i] = bkey[i] ^ 0x36363636;
    opad[i] = bkey[i] ^ 0x5C5C5C5C;
  }

  var hash = binl_md5(ipad.concat(rstr2binl(data)), 512 + data.length * 8);
  return binl2rstr(binl_md5(opad.concat(hash), 512 + 128));
}

/*
 * Convert a raw string to a hex string
 */
function rstr2hex(input)
{
  // try { hexcase } catch(e) { hexcase=0; }
  hexcase = 0;
  var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
  var output = "";
  var x;
  for(var i = 0; i < input.length; i++)
  {
    x = input.charCodeAt(i);
    output += hex_tab.charAt((x >>> 4) & 0x0F)
           +  hex_tab.charAt( x        & 0x0F);
  }
  return output;
}

/*
 * Convert a raw string to a base-64 string
 */
function rstr2b64(input)
{
  // try { b64pad } catch(e) { b64pad=''; }
  if (b64pad == "=") {} else { b64pad=''; } 
  var tab = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  var output = "";
  var len = input.length;
  for(var i = 0; i < len; i += 3)
  {
    var triplet = (input.charCodeAt(i) << 16)
                | (i + 1 < len ? input.charCodeAt(i+1) << 8 : 0)
                | (i + 2 < len ? input.charCodeAt(i+2)      : 0);
    for(var j = 0; j < 4; j++)
    {
      if(i * 8 + j * 6 > input.length * 8) output += b64pad;
      else output += tab.charAt((triplet >>> 6*(3-j)) & 0x3F);
    }
  }
  return output;
}

/*
 * Convert a raw string to an arbitrary string encoding
 */
function rstr2any(input, encoding)
{
  var divisor = encoding.length;
  var i, j, q, x, quotient;

  /* Convert to an array of 16-bit big-endian values, forming the dividend */
  var dividend = Array(Math.ceil(input.length / 2));
  for(i = 0; i < dividend.length; i++)
  {
    dividend[i] = (input.charCodeAt(i * 2) << 8) | input.charCodeAt(i * 2 + 1);
  }

  /*
   * Repeatedly perform a long division. The binary array forms the dividend,
   * the length of the encoding is the divisor. Once computed, the quotient
   * forms the dividend for the next step. All remainders are stored for later
   * use.
   */
  var full_length = Math.ceil(input.length * 8 /
                                    (Math.log(encoding.length) / Math.log(2)));
  var remainders = Array(full_length);
  for(j = 0; j < full_length; j++)
  {
    quotient = Array();
    x = 0;
    for(i = 0; i < dividend.length; i++)
    {
      x = (x << 16) + dividend[i];
      q = Math.floor(x / divisor);
      x -= q * divisor;
      if(quotient.length > 0 || q > 0)
        quotient[quotient.length] = q;
    }
    remainders[j] = x;
    dividend = quotient;
  }

  /* Convert the remainders to the output string */
  var output = "";
  for(i = remainders.length - 1; i >= 0; i--)
    output += encoding.charAt(remainders[i]);

  return output;
}

/*
 * Encode a string as utf-8.
 * For efficiency, this assumes the input is valid utf-16.
 */
function str2rstr_utf8(input)
{
  var output = "";
  var i = -1;
  var x, y;

  while(++i < input.length)
  {
    /* Decode utf-16 surrogate pairs */
    x = input.charCodeAt(i);
    y = i + 1 < input.length ? input.charCodeAt(i + 1) : 0;
    if(0xD800 <= x && x <= 0xDBFF && 0xDC00 <= y && y <= 0xDFFF)
    {
      x = 0x10000 + ((x & 0x03FF) << 10) + (y & 0x03FF);
      i++;
    }

    /* Encode output as utf-8 */
    if(x <= 0x7F)
      output += String.fromCharCode(x);
    else if(x <= 0x7FF)
      output += String.fromCharCode(0xC0 | ((x >>> 6 ) & 0x1F),
                                    0x80 | ( x         & 0x3F));
    else if(x <= 0xFFFF)
      output += String.fromCharCode(0xE0 | ((x >>> 12) & 0x0F),
                                    0x80 | ((x >>> 6 ) & 0x3F),
                                    0x80 | ( x         & 0x3F));
    else if(x <= 0x1FFFFF)
      output += String.fromCharCode(0xF0 | ((x >>> 18) & 0x07),
                                    0x80 | ((x >>> 12) & 0x3F),
                                    0x80 | ((x >>> 6 ) & 0x3F),
                                    0x80 | ( x         & 0x3F));
  }
  return output;
}

/*
 * Encode a string as utf-16
 */
function str2rstr_utf16le(input)
{
  var output = "";
  for(var i = 0; i < input.length; i++)
    output += String.fromCharCode( input.charCodeAt(i)        & 0xFF,
                                  (input.charCodeAt(i) >>> 8) & 0xFF);
  return output;
}

function str2rstr_utf16be(input)
{
  var output = "";
  for(var i = 0; i < input.length; i++)
    output += String.fromCharCode((input.charCodeAt(i) >>> 8) & 0xFF,
                                   input.charCodeAt(i)        & 0xFF);
  return output;
}

/*
 * Convert a raw string to an array of little-endian words
 * Characters >255 have their high-byte silently ignored.
 */
function rstr2binl(input)
{
  var output = Array(input.length >> 2);
  for(var i = 0; i < output.length; i++)
    output[i] = 0;
  for(var i = 0; i < input.length * 8; i += 8)
    output[i>>5] |= (input.charCodeAt(i / 8) & 0xFF) << (i%32);
  return output;
}

/*
 * Convert an array of little-endian words to a string
 */
function binl2rstr(input)
{
  var output = "";
  for(var i = 0; i < input.length * 32; i += 8)
    output += String.fromCharCode((input[i>>5] >>> (i % 32)) & 0xFF);
  return output;
}

/*
 * Calculate the MD5 of an array of little-endian words, and a bit length.
 */
function binl_md5(x, len)
{
  /* append padding */
  x[len >> 5] |= 0x80 << ((len) % 32);
  x[(((len + 64) >>> 9) << 4) + 14] = len;

  var a =  1732584193;
  var b = -271733879;
  var c = -1732584194;
  var d =  271733878;

  for(var i = 0; i < x.length; i += 16)
  {
    var olda = a;
    var oldb = b;
    var oldc = c;
    var oldd = d;

    a = md5_ff(a, b, c, d, x[i+ 0], 7 , -680876936);
    d = md5_ff(d, a, b, c, x[i+ 1], 12, -389564586);
    c = md5_ff(c, d, a, b, x[i+ 2], 17,  606105819);
    b = md5_ff(b, c, d, a, x[i+ 3], 22, -1044525330);
    a = md5_ff(a, b, c, d, x[i+ 4], 7 , -176418897);
    d = md5_ff(d, a, b, c, x[i+ 5], 12,  1200080426);
    c = md5_ff(c, d, a, b, x[i+ 6], 17, -1473231341);
    b = md5_ff(b, c, d, a, x[i+ 7], 22, -45705983);
    a = md5_ff(a, b, c, d, x[i+ 8], 7 ,  1770035416);
    d = md5_ff(d, a, b, c, x[i+ 9], 12, -1958414417);
    c = md5_ff(c, d, a, b, x[i+10], 17, -42063);
    b = md5_ff(b, c, d, a, x[i+11], 22, -1990404162);
    a = md5_ff(a, b, c, d, x[i+12], 7 ,  1804603682);
    d = md5_ff(d, a, b, c, x[i+13], 12, -40341101);
    c = md5_ff(c, d, a, b, x[i+14], 17, -1502002290);
    b = md5_ff(b, c, d, a, x[i+15], 22,  1236535329);

    a = md5_gg(a, b, c, d, x[i+ 1], 5 , -165796510);
    d = md5_gg(d, a, b, c, x[i+ 6], 9 , -1069501632);
    c = md5_gg(c, d, a, b, x[i+11], 14,  643717713);
    b = md5_gg(b, c, d, a, x[i+ 0], 20, -373897302);
    a = md5_gg(a, b, c, d, x[i+ 5], 5 , -701558691);
    d = md5_gg(d, a, b, c, x[i+10], 9 ,  38016083);
    c = md5_gg(c, d, a, b, x[i+15], 14, -660478335);
    b = md5_gg(b, c, d, a, x[i+ 4], 20, -405537848);
    a = md5_gg(a, b, c, d, x[i+ 9], 5 ,  568446438);
    d = md5_gg(d, a, b, c, x[i+14], 9 , -1019803690);
    c = md5_gg(c, d, a, b, x[i+ 3], 14, -187363961);
    b = md5_gg(b, c, d, a, x[i+ 8], 20,  1163531501);
    a = md5_gg(a, b, c, d, x[i+13], 5 , -1444681467);
    d = md5_gg(d, a, b, c, x[i+ 2], 9 , -51403784);
    c = md5_gg(c, d, a, b, x[i+ 7], 14,  1735328473);
    b = md5_gg(b, c, d, a, x[i+12], 20, -1926607734);

    a = md5_hh(a, b, c, d, x[i+ 5], 4 , -378558);
    d = md5_hh(d, a, b, c, x[i+ 8], 11, -2022574463);
    c = md5_hh(c, d, a, b, x[i+11], 16,  1839030562);
    b = md5_hh(b, c, d, a, x[i+14], 23, -35309556);
    a = md5_hh(a, b, c, d, x[i+ 1], 4 , -1530992060);
    d = md5_hh(d, a, b, c, x[i+ 4], 11,  1272893353);
    c = md5_hh(c, d, a, b, x[i+ 7], 16, -155497632);
    b = md5_hh(b, c, d, a, x[i+10], 23, -1094730640);
    a = md5_hh(a, b, c, d, x[i+13], 4 ,  681279174);
    d = md5_hh(d, a, b, c, x[i+ 0], 11, -358537222);
    c = md5_hh(c, d, a, b, x[i+ 3], 16, -722521979);
    b = md5_hh(b, c, d, a, x[i+ 6], 23,  76029189);
    a = md5_hh(a, b, c, d, x[i+ 9], 4 , -640364487);
    d = md5_hh(d, a, b, c, x[i+12], 11, -421815835);
    c = md5_hh(c, d, a, b, x[i+15], 16,  530742520);
    b = md5_hh(b, c, d, a, x[i+ 2], 23, -995338651);

    a = md5_ii(a, b, c, d, x[i+ 0], 6 , -198630844);
    d = md5_ii(d, a, b, c, x[i+ 7], 10,  1126891415);
    c = md5_ii(c, d, a, b, x[i+14], 15, -1416354905);
    b = md5_ii(b, c, d, a, x[i+ 5], 21, -57434055);
    a = md5_ii(a, b, c, d, x[i+12], 6 ,  1700485571);
    d = md5_ii(d, a, b, c, x[i+ 3], 10, -1894986606);
    c = md5_ii(c, d, a, b, x[i+10], 15, -1051523);
    b = md5_ii(b, c, d, a, x[i+ 1], 21, -2054922799);
    a = md5_ii(a, b, c, d, x[i+ 8], 6 ,  1873313359);
    d = md5_ii(d, a, b, c, x[i+15], 10, -30611744);
    c = md5_ii(c, d, a, b, x[i+ 6], 15, -1560198380);
    b = md5_ii(b, c, d, a, x[i+13], 21,  1309151649);
    a = md5_ii(a, b, c, d, x[i+ 4], 6 , -145523070);
    d = md5_ii(d, a, b, c, x[i+11], 10, -1120210379);
    c = md5_ii(c, d, a, b, x[i+ 2], 15,  718787259);
    b = md5_ii(b, c, d, a, x[i+ 9], 21, -343485551);

    a = safe_add(a, olda);
    b = safe_add(b, oldb);
    c = safe_add(c, oldc);
    d = safe_add(d, oldd);
  }
  return Array(a, b, c, d);
}

/*
 * These functions implement the four basic operations the algorithm uses.
 */
function md5_cmn(q, a, b, x, s, t)
{
  return safe_add(bit_rol(safe_add(safe_add(a, q), safe_add(x, t)), s),b);
}
function md5_ff(a, b, c, d, x, s, t)
{
  return md5_cmn((b & c) | ((~b) & d), a, b, x, s, t);
}
function md5_gg(a, b, c, d, x, s, t)
{
  return md5_cmn((b & d) | (c & (~d)), a, b, x, s, t);
}
function md5_hh(a, b, c, d, x, s, t)
{
  return md5_cmn(b ^ c ^ d, a, b, x, s, t);
}
function md5_ii(a, b, c, d, x, s, t)
{
  return md5_cmn(c ^ (b | (~d)), a, b, x, s, t);
}

/*
 * Add integers, wrapping at 2^32. This uses 16-bit operations internally
 * to work around bugs in some JS interpreters.
 */
function safe_add(x, y)
{
  var lsw = (x & 0xFFFF) + (y & 0xFFFF);
  var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
  return (msw << 16) | (lsw & 0xFFFF);
}

/*
 * Bitwise rotate a 32-bit number to the left.
 */
function bit_rol(num, cnt)
{
  return (num << cnt) | (num >>> (32 - cnt));
}



export const flask_multipart =  function(props, fileInput) {
  const CHUNK_SIZE = 50 * 1024 * 1024; //50 MB
  const uploadIdRegex = /<UploadId>(.*)<\/UploadId>/;
  
  // const fileInput = document.getElementById('file-input');

  
  /*
   * Utilities
   */
  const md5 = (fileName, blob) => 
    new Promise((resolve) => {
      const fr = new FileReader();

      fr.addEventListener('loadend', () => {
        const sum = rstr2b64(rstr_md5(fr.result));
        resolve(sum);
      });

      fr.readAsBinaryString(blob);
    });

  const serial = (items, callback) =>
    items.reduce((promise, item) =>
      promise.then(async () => {
        await callback(item);
      }) 
      , Promise.resolve());

  /*
    * Progress Bar
    */ 

  const createProgress = filename => {

    localStorage.setItem('filename', filename)
     
    let v = document.getElementById('vcolumn')

    const fileProgressContainer = v.getElementsByClassName('file-progress-container')[0];


    const progressElem = document.createElement('div');
    progressElem.classList.add('file-progress');

    const progressBarElem = document.createElement('div');
    progressBarElem.classList.add('file-progress-bar');
    // progressBarElem.textContent = fileName;
    progressElem.appendChild(progressBarElem);

    fileProgressContainer.appendChild(progressElem);

    document.getElementById("askSubText").style.display = 'block';
    // document.getElementsByClassName("Mpbar")[0].style.display = "block !important";
    document.getElementsByClassName("waitSub")[0].style.opacity = 0.5;


    console.log('create progress', progressElem, fileProgressContainer)
    return progressBarElem;
  }

  const updateProgress = (progressBar, fileName, current, total) => {

    // const percent = ((current/total) * 100).toFixed(2) + '%';
    const percent = ((current/total) * 100).toFixed(0) + '%';

    console.log('current progress', current)
    // progressBar.style.width = percent;

    const currentMb = (current / (1024 * 1024)).toFixed(2);
    const totalMb = (total / (1024 * 1024)).toFixed(2);

    // progressBar.textContent = `${fileName} - ${percent} (${currentMb} MB of ${totalMb} MB)`;
    // progressBar.textContent = `${percent} \\n uploading your video`;
    // progressBar.innerHTML = `${percent} <br> uploaded`;
    progressBar.innerHTML = `${percent} uploaded`;
  };

  const errorProgress = (progressBar, fileName, error) => {
    progressBar.classList.add('error');
    progressBar.textContent = `${fileName} - ${error}`;
  }


  const completeProgress = (progressBar, fileName, props) => {
    // progressBar.style.width = "100%";
    // progressBar.textContent = `${fileName} - Done`;
    progressBar.textContent = 'upload done';
    localStorage.setItem("progress", 1)

    var name = fileName
    localStorage.setItem('filename', name)
          
    localStorage.setItem('vid', 'https://d2wxuz9jomxjzh.cloudfront.net/' + name)
    document.getElementById("askSubText").style.display = 'none'
    document.getElementsByClassName("Mpbar")[0].style.display = "none !important";
    document.getElementsByClassName("waitSub")[0].style.opacity = 1;

// generate sub if video upload = 100% and lang selected as well
    if(localStorage.getItem('lang')) { 
      document.getElementsByClassName('waitSub')[0].click() 
    }
  
  }

  /*
   * Get Signed URLs
   */

  const getCreateUrl = async key => {
    // current url + '/create_multipart_upload'
  //   const url = new URL('create_multipart_upload', window.location.origin);
  //   // console.log('getCreateUrl 0', url)
  //   url.searchParams.append('key', key);

  //   // console.log('getCreateUrl1', url)
  //   // url = new URL('https://timbnxgena.execute-api.us-west-2.amazonaws.com/default');
  //   // url.searchParams.append('key', key);

  //   const response = await fetch(url);
  // //   // console.log('response', response, 'body', response.body )

  //   debugger

  //   const { url: createUrl } = await response.json();
  //   const { url: createUrl } = await response.json();

    const url = 'https://timbnxgena.execute-api.us-west-2.amazonaws.com/default/create_multipart_upload?key=' + key
    // console.log('getCreateUrl2', url)

    const response = await axios.get(url)

    // debugger
    // const createUrl = await response.data;
    
    // debugger

    // .then((response) => {
    //     console.log('axios res get', response, response.data)

    // const {url: createUrl} = await new URL(response.data);
    // const {url: href} = await new URL(response.data);

    // console.log('getCreateUrl3', createUrl)
    // return createUrl;

    //   })


  //   const response = await fetch(url);
  // //   // console.log('response', response, 'body', response.body )

  //   const { url: createUrl } = await response.json();
  // //   const { url: createUrl } = await response.json();

    // console.log('getCreateUrl3', createUrl)
    

    // return createUrl;
    return response.data;
  };



  const getUploadPartUrl = async (key, uploadId, part_number, contentMd5, contentLength) => {

    // const url = new URL('upload_part', window.location.origin);

    // console.log('raw url', url)
    // url.searchParams.append('key', key);
    // url.searchParams.append('upload_id', uploadId);
    // url.searchParams.append('part_number', part_number);
    // url.searchParams.append('content_md5', contentMd5);
    // url.searchParams.append('content_length', contentLength);


    // console.log('part  url', url)
    // console.log('href', url.href)
    // console.log('params', key, 'id', uploadId, 'part_number', part_number, 'md5', contentMd5, 'length',contentLength)

    // const response = await fetch(url);
    // const { url: uploadPartUrl } = await response.json();

    // console.log('uploadPart  url', uploadPartUrl)

    // return uploadPartUrl;


    // const url = 'https://i2ijo9uwz8.execute-api.us-west-2.amazonaws.com/default/upload_part?key=' + key + '&upload_id=' + uploadId + '&part_number=' + part_number + '&content_md5=' + contentMd5 + '&content_length=' + contentLength; 

    const xx = 'https://i2ijo9uwz8.execute-api.us-west-2.amazonaws.com/default/'
    const url = new URL('upload_part', xx)

    url.searchParams.append('key', key);
    url.searchParams.append('upload_id', uploadId);
    url.searchParams.append('part_number', part_number);
    url.searchParams.append('content_md5', contentMd5);
    url.searchParams.append('content_length', contentLength);


    const response = await axios.get(url)

    return response.data


  };



  const getCompleteUrl = async (key, uploadId) => {
    // const url = new URL('complete_multipart_upload', window.location.origin);
    // url.searchParams.append('key', key);
    // url.searchParams.append('upload_id', uploadId);

    // const response = await fetch(url);
    // const { url: completeUrl } = await response.json();

    // return completeUrl;

    // const url = 'https://mg863zpxw6.execute-api.us-west-2.amazonaws.com/default/complete_multipart_upload?key=' + key + '&upload_id=' + uploadId

    const xx = 'https://mg863zpxw6.execute-api.us-west-2.amazonaws.com/default/'
    const url = new URL('complete_multipart_upload', xx)

    url.searchParams.append('key', key);
    url.searchParams.append('upload_id', uploadId);

    const response = await axios.get(url)

    return response.data


  };

  /*
   * Make S3 Requests
   */

  const createUpload = async url => {
    
    console.log('UPLOAD URL', url)

    const response = await fetch(url, {
      method: 'POST',
    });

    if(response.status != 200) {
      throw "Create upload status code " + response.status;
    }

    const text = await response.text();
    return uploadIdRegex.exec(text)[1];
  }

  const uploadPart = (url, blob, contentMd5, progressCallback) => 
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = (event) => {
        const done = event.position || event.loaded;

        progressCallback(done);
      };

      xhr.onreadystatechange = (event) => {
        if (xhr.readyState == 4) {
          if(xhr.status == 200) {
            resolve(xhr.getResponseHeader('ETag'));
          } else {
            reject('Part upload status code ', xhr.status);
          }
        }
      };

      console.log('PART URL', url)

      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-MD5', contentMd5);
      xhr.send(blob);
    });

  const completeUpload = async (url, parts) => {
    let body = '<CompleteMultipartUpload xmlns="http://s3.amazonaws.com/doc/2006-03-01/">';
    Object.keys(parts).forEach(partNumber => {
      body += `
        <Part>
          <ETag>${parts[partNumber]}</ETag>
          <PartNumber>${partNumber}</PartNumber>
        </Part>
      `;
    });
    body += '</CompleteMultipartUpload>';

    const response = await fetch(url, {
      method: 'POST',
      body,
    });

    if(response.status != 200) {
      throw "Complete upload status code " + response.status;
    }

var uid = localStorage.getItem("uid")
var email = localStorage.getItem("email")

//  add file to user row in table
if(email) {
var geturl = 'https://6exomeey7e.execute-api.us-west-2.amazonaws.com/default/read_CRUD_table?email=' + email;
console.log('geturl', geturl)
axios.get(geturl).then(res => {


  var jsn
  var render
  //  if no user data OR jsonpath is empty
  if(!res.data.Item || !res.data.Item.jsonpath || !res.data.Item.jsonpath.S ) {
      jsn = localStorage.getItem('filename')
  } else {
      jsn = res.data.Item.jsonpath.S + ',' + localStorage.getItem('filename')
  }

  // check if the uploaded file is already in the list, obviosuly it will not!!!
  // if(!jsn.includes(localStorage.getItem('filename'))) {

  var url = 'https://g9163efqhh.execute-api.us-west-2.amazonaws.com/default/create_CRUD_table?email=' + email + '&jsonpath='+ jsn 
  axios.put(url).then((res) => {
    console.log('File added in table SUCCESS', res)
    
  }).catch(err => console.log(err))
// } 

}).catch(err => console.log(err))
}



  };

  /*
   * Event Handlers
   */

  const onFileChange = async ({ target: { files } }) => {
    // event.preventDefault();




    localStorage.setItem("progress", 0)

    // console.log('flask multipart props', props)
    // console.log('props 2', props.history)

    // files[0].name = files[0].name.replace(/ /g, '')
    // if user is not logged in => home.js
    // if user is logged in but not subscribed => home/workspace/alert < 50MB
    // if user is logged and subscribed => home/workspace/alert < 1GB

    // if(localStorage.getItem('uid'))

   var csub = checkSub()
// if file > 1 GB
    if( csub && files[0].size > 1073741274 ) { 

      alert("Video should be less than 1 GB\n\nTurtleclip currently accepts only videos that meet the following criteria:\n\nis less than 1.0 GiB\nis 20 minutes or less in duration")

      throw 'Video is too long.'
 
} 

// if not subscribed and > file > 50 MB 
//     if( !csub && files[0].size > 52428800 ) { 
      
//       alert("Video should be less than 50 MB. Upgrade to PRO for larger uploads")

//       throw 'Video is too long.'
 
// }
  


// name of the file is 'k'
    const k = (Date.now() + Math.random()).toString(36).replace('.','-') + Date.now() + '-' + files[0].name.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/ ]/gi, '');

    onfiledrop(k, files[0], props, fileInput);

    fileInput.disabled = true;


    serial(
      Array.from(files).map(file => ({
        file: file,
        // progressBar: createProgress(file.name),
      })),
      // async ({ file, progressBar }) => {
      async ({ file }) => {
        try {



          // var u = '3x456mKzu'
          // var r = {};
          // props.props.history.push({
          //     pathname: `/vidx/${u}`,
          //     state: { vid: r }
          // })

          // var previewEl = $("video#vid1");
          // previewEl[0].src = URL.createObjectURL(file);

          // var video = document.createElement('video');
          // video.preload = 'metadata';
          // video.autoplay = 'true';
          // document.getElementById("dropping").append(video);
          // video.src = URL.createObjectURL(file);

          // const k = Date.now() + '_' + file.name;

          // const createUrl = await getCreateUrl(file.name);
          // debugger
          const createUrl = await getCreateUrl(k);

          console.log("created URL", createUrl);
          console.log('file size', file.size, 'chunk size', CHUNK_SIZE);

          // var progressBar = createProgress(k)

          const uploadId = await createUpload(createUrl);

          console.log('uploadId', uploadId)

          var progressBar = createProgress(k)

    console.log('creating progress now ', progressBar)
          const parts = {};
          var part; 
          var etag;
          let partNumber = 1;
          let i = 0;
          while (i < file.size) {
            updateProgress(progressBar, k, i, file.size);

            console.log('progressBar', progressBar)

            part = file.slice(i , i + CHUNK_SIZE);
            const contentLength = part.size;
            console.log('content-length', contentLength)

            const contentMd5 = await md5(k, part);

            const partUrl = await getUploadPartUrl(
              k, uploadId, partNumber, contentMd5, contentLength
            );

            console.log("part URL", partUrl);
            etag = await uploadPart(partUrl, part, contentMd5, partProgress => {
              updateProgress(progressBar, k, i + partProgress, file.size);
            });

            parts[partNumber] = etag;

            partNumber += 1;
            i += CHUNK_SIZE;
          }

          const completeUrl = await getCompleteUrl(k, uploadId);
          console.log("completed URL", completeUrl);
          await completeUpload(completeUrl, parts);
     
          completeProgress(progressBar, k, props);

          fileInput.disabled = false;
        }
        catch (error) {

          try{
          errorProgress(progressBar, k, error);
        } catch(error) { console.log(error) }
        }
      });


  };


  fileInput.addEventListener('change', onFileChange);
  

}
