import axios from 'axios'; 


// import firebase from "gatsby-plugin-firebase";
// import firebase from "firebase/app";
// import 'firebase/auth';        // for authentication
// // import 'firebase/storage';     // for storage
// // import 'firebase/database';    // for realtime database
// import 'firebase/analytics';
import { useEffect } from 'react'; 




import {checkout, stripe_checkout_download} from './striperedirect';
// to render watermark free video
import {rendervid} from './rendervid';
import {checkSub} from './utils';


import { navigate } from "gatsby" ;
import {load_workspace} from '../components/workspace_util'; 


// const firebase = getFirebase();
// import useFirebase from './useFirebase';
// const firebase = useFirebase();


function setRender(email, firebase) {

  // useEffect(() => {
    // if (!firebase) return;
  
    // firebase.auth().onAuthStateChanged((user) => { ... });
  // }, [firebase]);



  var geturl = 'https://6exomeey7e.execute-api.us-west-2.amazonaws.com/default/read_CRUD_table?email=' + email;
  console.log('geturl', geturl)
  axios.get(geturl).then(res => {
  
  var render
  //  if no user data OR jsonpath is empty
  if(res.data.Item && res.data.Item.render || res.data.Item.render.S ) {
      
      render = res.data.Item.render.S
      localStorage.setItem('render', render)
}
  }).catch(e => console.log(e))



// }, [firebase]);

}




export function confirmEmail(firebase, props, href) {


    // var href = window.location.href

  // Confirm the link is a sign-in with email link.
if (firebase.auth().isSignInWithEmailLink(href)) {
  // Additional state parameters can also be passed via URL.
  // This can be used to continue the user's intended action before triggering
  // the sign-in operation.
  // Get the email if available. This should be available if the user completes
  // the flow on the same device where they started it.
  var email = window.localStorage.getItem('email');
  if (!email) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
    email = window.prompt('Please provide your email for confirmation');
  }
  // The client SDK will parse the code from the link for you.
  firebase.auth().signInWithEmailLink(email, href)
    .then((result) => {
      // Clear email from storage.
      // window.localStorage.removeItem('emailForSignIn');
      console.log('result', result)
      localStorage.clear()

      localStorage.setItem('uid', result.user.uid)
      localStorage.setItem('pic', result.user.photoURL)
      localStorage.setItem('email', result.user.email)
      localStorage.setItem('login', '1')

      console.log('props', props)


      // props.render_x('email', result.user.email)



      window.history.replaceState({}, document.title, window.location.pathname + '?w=' + result.user.uid);

      load_workspace(props)

 

      console.log('result.user', result.user)

      document.getElementById("navbardrop").src = result.user.photoURL;

      check_new_user(result, props)

      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser
    })
    .catch((error) => {
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
      console.log(error)
      document.getElementById("circleDown").style.display = 'none'
      navigate('/')

    });
}
}



export function passwordlessLogin(e, email, firebase) {

    e.preventDefault();
    e.stopPropagation();

var actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.


// if user is logged in, home will automatically push the url to workspace
  url: 'https://www.turtleclip.com/',
  // url: 'http://localhost:8000/',

  // firebase.auth().currentUser.uid,
  
  // This must be true.
  handleCodeInApp: true,
  // iOS: {
  //   bundleId: 'com.example.ios'
  // },
  // android: {
  //   packageName: 'com.example.android',
  //   installApp: true,
  //   minimumVersion: '12'
  // },
  // dynamicLinkDomain: 'example.page.link'
};


firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
  .then(function() {
    // Verification email sent.
    console.log('email link sent')
    document.getElementById('signx').style.display = 'none'
    document.getElementById("circleSign").style.display = 'none';
    
    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    window.localStorage.setItem('email', email);

    // should just be a simple widget displayed for 2 secs?
    alert('Please check email for link')

  })
  .catch(function(error) {
    // Error occurred. Inspect error.code.
     var errorCode = error.code;
    var errorMessage = error.message;
    // ...

    document.getElementById("circleSign").style.display = 'none';

    console.log(error)

  });


}

// called from home.js/Navbarhome.js
export function login(e, props, firebase) {
    console.log('login', props)

  // useEffect(() => {
    // if (!firebase) return;
    

    // console.log('current user', firebase.auth())

    e.preventDefault();
    e.stopPropagation();
    // firebase.auth()

    var d = {}

    // const app = firebase.initializeApp(firebaseConfig); 

    // console.log('!!!auth',new firebase.auth)
    // debugger
    const googleAuth =  new firebase.auth.GoogleAuthProvider(); 

    console.log('provider', googleAuth)

    // using the object we will authenticate the user. 
    firebase.auth().signInWithPopup(googleAuth).then(function(result) {
        var token = result.credential.accessToken;
        var user = result.user;

        console.log('firebase uid', result.user, result, 'uid', result.user.uid)

        // console.log('userCreds', result.user.uid, result.user.metadata)
        // console.log('userMail', result.user.email, result.user.displayName)

        d['uid'] = result.user.uid

        console.log('firebase Pic', firebase.auth().currentUser.photoURL, firebase.auth().currentUser.displayName)

        localStorage.clear()


        // props.render_x('uid', result.user.uid)
        console.log('rpops', props)
        // props.render_x('pic', firebase.auth().currentUser.photoURL)
        // props.props.render_x('email', result.user.email)



        localStorage.setItem('uid', result.user.uid)
        localStorage.setItem('pic', firebase.auth().currentUser.photoURL)
        localStorage.setItem('email', result.user.email)
        localStorage.setItem('login', '1')


        check_new_user(result, props)

        var work_id = result.user.uid

        // var suburl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + result.user.email

        // axios.get(suburl).then(response => {

        //   console.log('cn', response.data.Item)
        //   localStorage.setItem('cn', response.data.Item.sub.S)
        //   // // props.render_x('subscribe', response.data.Item.sub.S)
  

        // // if the item doesnt exist in subscribe table
        // }).catch(e => {
        //   localStorage.setItem('cn', '0');         
        //   // // props.render_x('subscribe', '0');
        // })




        // var emurl = 'https://v7w4i2ff2g.execute-api.us-west-2.amazonaws.com/default/add_email?uid=' + result.user.uid + '&email=' + result.user.email

        // axios.get(emurl).then(res => console.log('email added')).catch(err => console.log(err))


        console.log('props', props)

    // props.props.history.push({
    //       pathname: `/workspace/${work_id}`
    //       ,
    //       state: { videos: d }
    // })

        navigate(`/workspace/?w=${work_id}`, {
          state: { videos: d }
        })

    // window.location.href=window.location.origin + '/workspace';



    }).catch(function(error) {
        console.log('Firebase Auth Error', error)
        document.getElementById("circleSign").style.display = 'none';
    }) ; 

    // console.log('firebase uid', firebase.auth().currentUser.uid)

// }, [firebase]);    

}


// called on pricing page
export function login_pricing_home(e, props, firebase) {
    console.log('login', e.currentTarget)

  // useEffect(() => { 
    // if (!firebase) return;
  

    e.preventDefault();
    e.stopPropagation();

    // console.log('current user', firebase.auth())


    // firebase.auth()

    var d = {}

    // const app = firebase.initializeApp(firebaseConfig); 

    const googleAuth =  new firebase.auth.GoogleAuthProvider(); 

    console.log('provider', googleAuth)

    // using the object we will authenticate the user. 
    firebase.auth().signInWithPopup(googleAuth).then(function(result) {
        var token = result.credential.accessToken;
        var user = result.user;

        console.log('firebase uid', result.user, result, 'uid', result.user.uid)

        // console.log('userCreds', result.user.uid, result.user.metadata)
        // console.log('userMail', result.user.email, result.user.displayName)

        d['uid'] = result.user.uid

        console.log('firebase Pic', firebase.auth().currentUser.photoURL, firebase.auth().currentUser.displayName)

        // // props.render_x('uid', result.user.uid)
        // // props.render_x('pic', firebase.auth().currentUser.photoURL)
        // // props.render_x('email', result.user.email)


        localStorage.clear()
        localStorage.setItem('uid', result.user.uid)
        localStorage.setItem('pic', firebase.auth().currentUser.photoURL)
        localStorage.setItem('email', result.user.email)
        localStorage.setItem('login', '1')

        // create_new_user();

        check_new_user(result, props)

        var work_id = result.user.uid

        var suburl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + result.user.email

        axios.get(suburl).then(response => {

          console.log('subscribe', response.data.Item)

          localStorage.setItem('cn', response.data.Item.count.S)
          // // props.render_x('subscribe', response.data.Item.count.S)
          

        // if the item doesnt exist in subscribe table
        }).catch(e => {
          // // props.render_x('subscribe', '0')
          localStorage.setItem('cn', '0')
        })



        // var emurl = 'https://v7w4i2ff2g.execute-api.us-west-2.amazonaws.com/default/add_email?uid=' + result.user.uid + '&email=' + result.user.email

        // axios.get(emurl).then(res => console.log('email added')).catch(err => console.log(err)) 

        console.log('props', props)

    // props.history.push({
    //       pathname: `/workspace/${work_id}`
    //       ,
    //       state: { videos: d }
    // })

      navigate(`/workspace/?w=${work_id}`, {
          state: { videos: d }
        })

    // window.location.href=window.location.origin + '/workspace';



    }).catch(function(error) {
        console.log('Firebase Auth Error', error)

        try {
            document.getElementById("circlePricing").style.display = 'none'
        } catch(e) {
            console.log(e)
        }
    }) ; 

    // console.log('firebase uid', firebase.auth().currentUser.uid)
    
// }, [firebase]);

}


// on PRICING page
// login + pay
export function login_sub(e, props, firebase) {

  // useEffect(() => {
    // if (!firebase) return;
  


    e.preventDefault();
    e.stopPropagation();

    console.log('login', e.currentTarget, props)

    var d = {}

    // const app = firebase.initializeApp(firebaseConfig); 

    const googleAuth =  new firebase.auth.GoogleAuthProvider(); 

    console.log('provider', googleAuth)

    // using the object we will authenticate the user. 
    firebase.auth().signInWithPopup(googleAuth).then(function(result) {
        var token = result.credential.accessToken;
        var user = result.user;

        console.log('firebase uid', result.user, result, 'uid', result.user.uid)

        // console.log('userCreds', result.user.uid, result.user.metadata)
        // console.log('userMail', result.user.email, result.user.displayName)

        d['uid'] = result.user.uid

        console.log('firebase Pic', firebase.auth().currentUser.photoURL, firebase.auth().currentUser.displayName)

        // props.render_x('uid', result.user.uid)
        // props.render_x('pic', firebase.auth().currentUser.photoURL)
        // props.render_x('email', result.user.email)


        localStorage.clear()
        localStorage.setItem('uid', result.user.uid)
        localStorage.setItem('pic', firebase.auth().currentUser.photoURL)
        localStorage.setItem('email', result.user.email)
        localStorage.setItem('login', '1')

        // create_new_user();
        check_new_user(result, props)

        var work_id = result.user.uid

        var suburl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + result.user.email

        axios.get(suburl).then(response => {

          console.log('subscribe', response.data.Item)

          // props.render_x('subscribe', response.data.Item.count.S)
          localStorage.setItem('cn', response.data.Item.count.S)
          
    //       props.history.push({
    //       pathname: `/workspace/${work_id}`,
    //       state: { videos: d }
    // })

          navigate(`/workspace/?w=${work_id}`, {
            state: { videos: d }
          })

        // if the item doesnt exist in subscribe table
        }).catch(e => {
          // props.render_x('subscribe', '0')
          localStorage.setItem('cn', '0')


          console.log('calling PaymentButton')

          checkout(work_id, result.user.email)

        })



        // var emurl = 'https://v7w4i2ff2g.execute-api.us-west-2.amazonaws.com/default/add_email?uid=' + result.user.uid + '&email=' + result.user.email

        // axios.get(emurl).then(res => console.log('email added')).catch(err => console.log(err)) 

        console.log('props', props)

        // window.location.reload()


    }).catch(function(error) {
        console.log('Firebase Auth Error', error)
        
        try {
            document.getElementById("circlePricing").style.display = 'none'
        } catch(e) {
            console.log(e)
        }

    }) ; 

    // console.log('firebase uid', firebase.auth().currentUser.uid)
   
   // }, [firebase]); 

}



// ON VIDx page
// only login 
export function login_in_vid(props, firebase) {

  // useEffect(() => { 
    // if (!firebase) return;


    console.log('props', props)

    var d = {}

    // const app = firebase.initializeApp(firebaseConfig); 

    const googleAuth =  new firebase.auth.GoogleAuthProvider(); 

    console.log('provider', googleAuth)

    // using the object we will authenticate the user. 
    firebase.auth().signInWithPopup(googleAuth).then(function(result) {
        var token = result.credential.accessToken;
        var user = result.user;

        console.log('firebase uid', result.user, result, 'uid', result.user.uid)

        // console.log('userCreds', result.user.uid, result.user.metadata)
        // console.log('userMail', result.user.email, result.user.displayName)

        d['uid'] = result.user.uid

        console.log('firebase Pic', firebase.auth().currentUser.photoURL, firebase.auth().currentUser.displayName)


        // props.render_x('uid', result.user.uid)
        // props.render_x('pic', firebase.auth().currentUser.photoURL)
        // props.render_x('email', result.user.email)


        // localStorage.clear()
        localStorage.setItem('uid', result.user.uid)
        localStorage.setItem('pic', firebase.auth().currentUser.photoURL)
        localStorage.setItem('email', result.user.email)
        

        storejson_s3_table()

    }).catch(function(error) {
        console.log('Firebase Auth Error', error)
    }) ; 

// }, [firebase]); 

}



// ON VIDx page
// only login 
export function login_in_create(props, firebase) {

  // useEffect(() => {
    // if (!firebase) return;

    // console.log('props', props)


    var d = {}

    // const app = firebase.initializeApp(firebaseConfig); 

    const googleAuth =  new firebase.auth.GoogleAuthProvider(); 

    console.log('provider', googleAuth)

    // using the object we will authenticate the user. 
    firebase.auth().signInWithPopup(googleAuth).then(function(result) {
        var token = result.credential.accessToken;
        var user = result.user;

        // console.log('firebase uid', result.user, result, 'uid', result.user.uid)

        // console.log('userCreds', result.user.uid, result.user.metadata)
        // console.log('userMail', result.user.email, result.user.displayName)

        // debugger

        d['uid'] = result.user.uid

        console.log('d[uid]', d['uid'])

        // console.log('firebase Pic', firebase.auth().currentUser.photoURL, firebase.auth().currentUser.displayName)



        // props.render_x('uid', result.user.uid)
        // props.render_x('pic', firebase.auth().currentUser.photoURL)
        // props.render_x('email', result.user.email)


        // localStorage.clear()
        localStorage.setItem('uid', result.user.uid)
        localStorage.setItem('pic', firebase.auth().currentUser.photoURL)
        localStorage.setItem('email', result.user.email)
        

        storejson_s3_table()

        document.getElementById('downloadx').click();

    }).catch(function(error) {
        console.log('Firebase Auth Error', error)
    }) ; 

// }, [firebase]);

}

// ON VIDx page, when you click on workspace
// only login 
export function login_in_work(e, props, firebase) {

  // useEffect(() => {
    // if (!firebase) return;


    e.preventDefault();
    e.stopPropagation();

    console.log('login', e.currentTarget, props)


    // console.log('current user', firebase.auth())


    // firebase.auth()

    // var d = {}

    // const app = firebase.initializeApp(firebaseConfig); 

    const googleAuth =  new firebase.auth.GoogleAuthProvider(); 

    console.log('provider', googleAuth)


    // using the object we will authenticate the user. 
    firebase.auth().signInWithPopup(googleAuth).then(function(result) {
        var token = result.credential.accessToken;
        var user = result.user;

        console.log('firebase uid', result.user, result )
        // ('uid', result.user.uid)
        console.log('props!!', props)

        // debugger

        // console.log('userCreds', result.user.uid, result.user.metadata)
        // console.log('userMail', result.user.email, result.user.displayName)

        // debugger

        // d['uid'] = result.user.uid

        // console.log('d', d)

        // console.log('firebase Pic', firebase.auth().currentUser.photoURL, firebase.auth().currentUser.displayName)

        console.log('@@@ props', props)

        // props.render_x('uid', result.user.uid)
        // props.render_x('pic', firebase.auth().currentUser.photoURL)
        // props.render_x('email', result.user.email)

        // localStorage.clear()
        localStorage.setItem('uid', result.user.uid)
        localStorage.setItem('pic', firebase.auth().currentUser.photoURL)
        localStorage.setItem('email', result.user.email)
        storejson_s3_table()

        console.log('props in login', props)

        var suburl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + result.user.email
        var d = {}
        d['uid'] = result.user.uid


        axios.get(suburl).then(response => {

          console.log('subscribe', response.data.Item)

          localStorage.setItem('cn', response.data.Item.count.S)
          // props.render_x('subscribe', response.data.Item.count.S)

          console.log('props', props)



          // props.history.push({
          //       pathname: `/workspace/${result.user.uid}`
          //       ,
          //       state: { videos: d }
          // })


        navigate(`/workspace/?w=${result.user.uid}`, {
          state: { videos: d }
        })

        // if the item doesnt exist in subscribe table
        }).catch(e => {
          // props.render_x('subscribe', '0')
          localStorage.setItem('cn', '0')

          console.log('props err', props.x)

          // props.history.push({
          //       pathname: `/workspace/${result.user.uid}`
          //       ,
          //       state: { videos: d }
          // })

        navigate(`/workspace/?w=${result.user.uid}`, {
          state: { videos: d }
        })

        })




    }).catch(function(error) {
        console.log('ERROR !Firebase Auth Error!', error)
    }) ; 

  // }, [firebase]);

}


function check_new_user(result, props) {

      console.log('if new user',result.additionalUserInfo.isNewUser)
      // if newUser - true 
      if(result.additionalUserInfo.isNewUser) {


        var store_email = 'https://olwknoxo6c.execute-api.us-west-2.amazonaws.com/default/turtleclip-store-email' + '?uid=' + result.user.uid + '&email=' + result.user.email

        axios.get(store_email).then(response => {

            console.log('email and uid stored')
          // // props.render_x('subscribe', response.data.Item.sub.S)

        }).catch((e) => {
        console.log(e)
        })


// no no NOT READ, update the Subscribe table, translatebear_newPayTable UPDATES count!

        // var userLink = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + result.user.email

        var userLink = 'https://sr2bfyh6e6.execute-api.us-west-2.amazonaws.com/default/create_subscribe_table?email=' + result.user.email


       axios.get(userLink).then(res => { 

          console.log('res.cn', res.data.cn)
        
          console.log('updated paytable for new user')
          localStorage.setItem('cn', res.data.cn)
          // props.render_x('subscribe', '1')

          document.getElementById('rem').innerText = localStorage.getItem('cn')


        }).catch(e => {
          console.log(e, 'couldnt update the paytable for new user')
        })


      }




}


function create_new_user() {

var geturl = 'https://6exomeey7e.execute-api.us-west-2.amazonaws.com/default/read_CRUD_table?email=' + localStorage.getItem('email');
console.log('geturl', geturl)
axios.get(geturl).then(res => {
  
  //  if no user data
  if(!res.data.Item || !res.data.Item.jsonpath) {
    
    console.log('no res.data.Item exists')


  var url = 'https://g9163efqhh.execute-api.us-west-2.amazonaws.com/default/create_CRUD_table?email=' + localStorage.getItem('email') + '&jsonpath='+ '' 
  axios.put(url).then((res) => {console.log('New User added in table SUCCESS', res)}).catch(err => console.log(err))

} 

  setRender(localStorage.getItem('email'))

}).catch(err => {

  console.log(err, 'User doesnt exists')
  var url = 'https://g9163efqhh.execute-api.us-west-2.amazonaws.com/default/create_CRUD_table?email=' + localStorage.getItem('email') + '&jsonpath='+ '' 
  axios.put(url).then((res) => {console.log('New User added in table SUCCESS', res)
    localStorage.setItem('render', '0')
}).catch(err => console.log(err))


})


}

function storejson_s3_table() {

    console.log("SAVING JSON")

    var fname = localStorage.getItem('filename').split('.')[0] + '.json'
    var url = 'https://xplks8isk4.execute-api.us-west-2.amazonaws.com/default/json_to_s3?key=' + fname

    axios.post(url, JSON.stringify(JSON.parse(JSON.stringify(localStorage))),{
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((res) => {
            console.log('POST JSON res', res)}).catch(err => {console.log('json post ERROR', err)})



// send updated/new item to Table 

var uid = localStorage.getItem("uid")
var email = localStorage.getItem("email")

//  add file to user row in table
if(email) {
var geturl = 'https://6exomeey7e.execute-api.us-west-2.amazonaws.com/default/read_CRUD_table?email=' + email;
console.log('geturl', geturl)
axios.get(geturl).then(res => {
  var jsn
  var render
  //  if no user data OR jsonpath is empty
  if(!res.data.Item || !res.data.Item.jsonpath || !res.data.Item.jsonpath.S ) {
      jsn = localStorage.getItem('filename')
      render = '0'
  } else {
  // if filename already exists in jsn
   
      jsn = res.data.Item.jsonpath.S
    if(!jsn.includes(localStorage.getItem('filename'))) {jsn = res.data.Item.jsonpath.S + ',' + localStorage.getItem('filename') }
      render = res.data.Item.render.S

  }

    localStorage.setItem('render', render)
  // check if the uploaded file is already in the list, obviosuly it will not!!!
  // if(!jsn.includes(localStorage.getItem('filename'))) {

  var url = 'https://g9163efqhh.execute-api.us-west-2.amazonaws.com/default/create_CRUD_table?email=' + email + '&jsonpath='+ jsn
  axios.put(url).then((res) => {console.log('File added in table SUCCESS', res)}).catch(err => console.log(err))
// } 

}).catch(err => console.log(err))
}


}
// // Signs-out of Friendly Chat.
// function signOut() {
//   // Sign out of Firebase.
//   firebase.auth().signOut();
// }


// Returns the signed-in user's profile pic URL.
export function getProfilePicUrl(firebase) {

  // useEffect(() => {
    // if (!firebase) return;
  

  console.log('auth', firebase.auth().currentUser)

  return firebase.auth().currentUser.photoURL || '/images/profile_placeholder.png';

  // }, [firebase]);

}

// Returns the signed-in user's display name.
export function getUserName(firebase) {

  // useEffect(() => { 
    // if (!firebase) return;

  return firebase.auth().currentUser.displayName;
    // }, [firebase]);

}

// Returns true if a user is signed-in.
export function isUserSignedIn(firebase) {

  // useEffect(() => {
    // if (!firebase) return;

  return !!firebase.auth().currentUser;

    // }, [firebase]);

}



// Signs-out of Friendly Chat.
export function signOut(firebase) {

  // useEffect(() => {
    // if (!firebase) return;

  // Sign out of Firebase.
  localStorage.clear()
  window.location.href = window.location.origin;

  firebase.auth().signOut()

  // }, [firebase]);


}










// ON DOWNLOAD page
// login + subscribe
export function login_down(e, props, firebase) {
    console.log('login', e.currentTarget)

  // useEffect(() => {
    // if (!firebase) return;
// }, [firebase]);

      e.preventDefault();
      e.stopPropagation();


    // console.log('current user', firebase.auth())


    // firebase.auth()

    var d = {}

    // const app = firebase.initializeApp(firebaseConfig); 

    const googleAuth =  new firebase.auth.GoogleAuthProvider(); 

    console.log('provider', googleAuth)

    // using the object we will authenticate the user. 
    firebase.auth().signInWithPopup(googleAuth).then(function(result) {
        var token = result.credential.accessToken;
        var user = result.user;

        console.log('firebase uid', result.user, result, 'uid', result.user.uid)

        // console.log('userCreds', result.user.uid, result.user.metadata)
        // console.log('userMail', result.user.email, result.user.displayName)

        d['uid'] = result.user.uid

        console.log('firebase Pic', firebase.auth().currentUser.photoURL, firebase.auth().currentUser.displayName)

        // localStorage.clear()
        localStorage.setItem('uid', result.user.uid)
        localStorage.setItem('pic', firebase.auth().currentUser.photoURL)
        localStorage.setItem('email', result.user.email)

        storejson_s3_table()

        // var down_id = result.user.uid

        document.getElementById("circleDown").style.display = "block"

        var dx = checkSub()

        var down_id = window.location.href.split('/').slice(-1)[0]

// if not subscribed already
        if(!dx) {stripe_checkout_download(down_id, result.user.email);}
// if subscribed already
        else { rendervid(props) }

        // document.getElementById("circleDown").style.display = "none"


    }).catch(function(error) {
        console.log('Firebase Auth Error', error)
    }) ; 


}



// ON DOWNLOAD page
// only login 
export function only_login(e, props, firebase) {


  // useEffect(() => {
    // if (!firebase) return;
// }, [firebase]);


    e.preventDefault();
    e.stopPropagation();

    console.log('login', e.currentTarget)


    // console.log('current user', firebase.auth())


    // firebase.auth()

    var d = {}

    // const app = firebase.initializeApp(firebaseConfig); 

    const googleAuth =  new firebase.auth.GoogleAuthProvider(); 

    console.log('provider', googleAuth)

    // using the object we will authenticate the user. 
    firebase.auth().signInWithPopup(googleAuth).then(function(result) {
        var token = result.credential.accessToken;
        var user = result.user;

        console.log('firebase uid', result.user, result, 'uid', result.user.uid)

        // console.log('userCreds', result.user.uid, result.user.metadata)
        // console.log('userMail', result.user.email, result.user.displayName)

        d['uid'] = result.user.uid

        console.log('firebase Pic', firebase.auth().currentUser.photoURL, firebase.auth().currentUser.displayName)

        // localStorage.clear()
        localStorage.setItem('uid', result.user.uid)
        localStorage.setItem('pic', firebase.auth().currentUser.photoURL)
        localStorage.setItem('email', result.user.email)
        storejson_s3_table()


        // downloading here, bcoz fn is async 

  // var xout = localStorage.getItem('xout')


  // var url = 'https://d2wxuz9jomxjzh.cloudfront.net/' + xout + '?cachebuster=' + Date.now();
  var url = localStorage.getItem('dvid')
  console.log('URL', url);
  // a.setAttribute('download', res.data)
  var a = document.createElement('a');
  // a.target = '_blank';
  a.href = url;
  // a.style.display = 'none';
  // document.getElementById('subdiv').appendChild(a); // Required for Firefox
  a.click(); 




    }).catch(function(error) {
        console.log('Firebase Auth Error', error)
    }) ; 


}





