import axios from 'axios'; 

import {checkSub} from './utils';
// import { navigate } from "@reach/router"

import React from "react"
import { navigate } from "gatsby"

export const onfiledrop = function(name, file, props, file_drop) {
    console.log('PROPS onfiledrop', props)


// if video MIME type is not supported, need to check this in browser
    let blob
    // if(file.type.split('/')[0] == 'video')
    blob = new Blob([file], { type: "video/ogg" });

    // let blob
    // if(file.type.split('/')[0] == 'video')
    //     blob = new Blob([file], { type: "video/ogg" });
    // else blob = new Blob([file], { type: "audio/mp3" });


// const files = event.target.files;
//   if (files && files.length > 0) {
    const targetFile = blob;

    console.log('targetFile', targetFile)


    // debugger
    // console.log(props.props.boxes2)

    if(props.boxes2) {
        while(props.boxes2.length) {
            props.pop_boxes(0)
        }
        props.invalidate(false);
    }

    localStorage.setItem('nBox', 0)


    // props.props.boxes2 

    // console.log('!!PROPS onfiledrop', props.state.uid)

    var uid = localStorage.getItem("uid")
    var pic = localStorage.getItem("pic")
    var subscribe = localStorage.getItem("cn")
    var email = localStorage.getItem('email')
    var login = localStorage.getItem('login')

    var pa = document.getElementsByClassName("up-try")[0]

    console.log('LOCAL STORAGE BEFORE CLEAR', localStorage)

    localStorage.clear()

    if(uid) localStorage.setItem("uid", uid)
    if(uid && pic) localStorage.setItem("pic", pic)
    if(uid && subscribe) localStorage.setItem("cn", subscribe)
    if(uid && email) localStorage.setItem('email', email)
    if(uid && login) localStorage.setItem('login', '1')

    localStorage.setItem("reload", "0")
// myDropzone.on("selectedfiles", function(files, done) {
  // Add default option box for each preview.
  // var defaultRadioButton = Dropzone.createElement('<div class="default_pic_container"><input type="radio" name="default_pic" value="'+file.name+'" /> Default</div>');
  // file.previewElement.appendChild(defaultRadioButton);
    localStorage.setItem("progress", 0)


    // var file = files[0]

    console.log('addedfile', file) 
    localStorage.setItem('filename', name)
    var r = {}
    // r['meta'] = [{'start_time': '00:00.000', 'end_time': '00:00.14', 'words': ' '}];
    r['meta'] = [{'start_time': '', 'end_time': '', 'words': ''}];

    var m = [{'start_time': '', 'end_time': '', 'words': ''}];
    localStorage.setItem('meta', JSON.stringify(m))
    var s = ['','','','','']
    localStorage.setItem('seekbar', JSON.stringify(s))
    localStorage.setItem('substyle', JSON.stringify(s))


    // to show the subtitles or the button
    localStorage.setItem("showSub", "0")




    localStorage.setItem('subx', JSON.stringify([]));
    localStorage.setItem('en', JSON.stringify([]));
    localStorage.setItem('strt', JSON.stringify([]));
    localStorage.setItem('head', 'ADD HEADING HERE!!')

    // r['meta'] = [{}];
    // var u = 'xx1MzPY9';
    var u = Date.now().toString();
    r["uuid"] = u;
    localStorage.setItem('uuid', u);

//     props.props.history.push({
//     pathname: `/vidx/${u}`,
//     state: { vid: r }
// })

    var video = document.createElement('video');
    video.preload = 'metadata';
    video.autoplay = 'true';
  //  video.hidden = 'true';
    
    pa.append(video);
    video.style.zIndex = -100
    
    localStorage.setItem("Yes", 'false');    

//if browser is firefox
if(navigator.userAgent.indexOf("Firefox") != -1 ){


    video.onloadeddata = function() {

    try {

    // window.URL.revokeObjectURL(video.src);

    // in secs - 6.025 secs
    var d = video.duration;

// 20*60*1000 milisec == 20 mins

    var csub = checkSub()
// if subscribed and > 20 minutes
    if( csub && parseFloat(d)*1000 > 1200000 ) { 

        video.remove();

        file_drop.disabled = false;
      
      alert("Video should be 20 minutes or less\n\nTurtleclip currently accepts only videos that meet the following criteria:\nis less than 1.0 GiB\nis 20 minutes or less in duration")

      throw 'Video is too long.'
 
} 


// if not subscribed and > 8 minutes = 8*60*1000
    if( !csub && parseFloat(d)*1000 > 480000 ) { 

        video.remove();

        file_drop.disabled = false;
      
      alert("Video should be 8 minutes or less. Upgrade to PRO for longer uploads")

      throw 'Video is too long.'
 
} 
    
    var h = video.offsetHeight + 'px';
    var w = video.offsetWidth + 'px';
    console.log('h','w',video.offsetHeight,video.offsetWidth)
    

    video.display = 'none';
    video.muted = true;

    // try {

    // if (document.getElementById("alert").style.display = "block") {
    //     var replace=1
    // }
    //     document.getElementById("alert").style.display = "none"
    // } catch {}
    
    // document.getElementById("dropping").removeChild(video);

    console.log('dropstate', props)
    // props.props.setState({...props.props.state, duration: d, height: h })

    localStorage.setItem('duration',d)
    localStorage.setItem('height',video.offsetHeight + 'px')
    localStorage.setItem('width',video.offsetWidth +'px')
    
    
    r['height'] = video.offsetHeight
    r['width'] = video.offsetWidth
    r['duration'] = d
    r['yes']  = 'false';

    console.log('r',r, video.readyState)

// tryinng
        // navigate(`/workspace/?w=${u}`, 
        // navigate(`/workspace/`, 
        //   {state: { videos: r }} )

        // navigate('/vidx/', { replace: true }); 

    const namef = name.split('.')[0] 
    // + '-' + name.split('.')[1]




        console.log('1 window.location.href', window.location.href)

        navigate(`/vidx/?v=${namef}`, 
        { state: { vid: r }} )

        console.log('2 window.location.href', window.location.href)

    localStorage.setItem('blob', (window.URL || window.webkitURL).createObjectURL(targetFile))

    console.log('3 window.location.href', window.location.href)

//     var previewEl = document.getElementById("vid1")

//     console.log('window.URL.createObjectURL(file)', window.URL.createObjectURL(file))

//     previewEl.src = window.URL.createObjectURL(file);


// try {
//     document.getElementById("lang").style.display = "block"
// } catch {}

    console.log("!!!!!! NEW URL HAS BEEN PUSHED!!!!!!!!")
    console.log('xxvidxx', window.location)
    console.log('subx', JSON.parse(localStorage.getItem('subx')));
    // var progressBar = createProgress(file.name)



} catch(e) { console.log(e, '!!! ONFILEDROP !!!'); }

  }
  
 } else {
 
 
 
     video.onloadedmetadata = function() {

    try {

    // window.URL.revokeObjectURL(video.src);

    // in secs - 6.025 secs
    var d = video.duration;

// 20*60*1000 milisec == 20 mins

    var csub = checkSub()
// if subscribed and > 20 minutes
    if( csub && parseFloat(d)*1000 > 1200000 ) { 

        video.remove();

        file_drop.disabled = false;
      
      alert("Video should be 20 minutes or less\n\nTurtleclip currently accepts only videos that meet the following criteria:\nis less than 1.0 GiB\nis 20 minutes or less in duration")

      throw 'Video is too long.'
 
} 


// if not subscribed and > 8 minutes = 8*60*1000
    if( !csub && parseFloat(d)*1000 > 480000 ) { 

        video.remove();

        file_drop.disabled = false;
      
      alert("Video should be 8 minutes or less. Upgrade to PRO for longer uploads")

      throw 'Video is too long.'
 
} 
    
    var h = video.offsetHeight + 'px';
    var w = video.offsetWidth + 'px';
    console.log('h','w',video.offsetHeight,video.offsetWidth)
    

    video.display = 'none';
    video.muted = true;

    // try {

    // if (document.getElementById("alert").style.display = "block") {
    //     var replace=1
    // }
    //     document.getElementById("alert").style.display = "none"
    // } catch {}
    
    // document.getElementById("dropping").removeChild(video);

    console.log('dropstate', props)
    // props.props.setState({...props.props.state, duration: d, height: h })

    localStorage.setItem('duration',d)
    localStorage.setItem('height',video.offsetHeight + 'px')
    localStorage.setItem('width',video.offsetWidth +'px')
    
    
    r['height'] = video.offsetHeight
    r['width'] = video.offsetWidth
    r['duration'] = d
    r['yes']  = 'false';

    console.log('r',r, video.readyState)
    
// tryinng
        // navigate(`/workspace/?w=${u}`, 
        // navigate(`/workspace/`, 
        //   {state: { videos: r }} )

        // navigate('/vidx/', { replace: true }); 

    const namef = name.split('.')[0] 
    // + '-' + name.split('.')[1]

        console.log('1 window.location.href', window.location.href)

        navigate(`/vidx/?v=${namef}`, 
        { state: { vid: r }} )

        console.log('2 window.location.href', window.location.href)


    localStorage.setItem('blob', (window.URL || window.webkitURL).createObjectURL(targetFile))

    console.log('3 window.location.href', window.location.href)

//     var previewEl = document.getElementById("vid1")

//     console.log('window.URL.createObjectURL(file)', window.URL.createObjectURL(file))

//     previewEl.src = window.URL.createObjectURL(file);


// try {
//     document.getElementById("lang").style.display = "block"
// } catch {}

    console.log("!!!!!! NEW URL HAS BEEN PUSHED!!!!!!!!")
    console.log('xxvidxx', window.location)
    console.log('subx', JSON.parse(localStorage.getItem('subx')));
    // var progressBar = createProgress(file.name)



} catch(e) { console.log(e, '!!! ONFILEDROP !!!'); }

  }
 
 
 }

    // video.src = window.URL.createObjectURL(file);



    try {
      const objectURL = (window.URL || window.webkitURL).createObjectURL(targetFile);
      video.src = objectURL;
      // window.URL.revokeObjectURL(objectURL);
    }
    catch(e) {
      try {
        // Fallback if createObjectURL is not supported
        const fileReader = new FileReader();
        fileReader.onload = (evt) => {
            video.src = evt.target.result;
        };
        fileReader.readAsDataURL(file);
      }
      catch(e) {
        console.log(`File Upload not supported: ${e}`);
      }
    }

//    var previewEl = $("video#vid1");

//    if (previewEl[0].canPlayType(file.type) !== "no"){
//       var fileURL = URL.createObjectURL(file);
// }
      
};
