
/* global $ */

import {storeSub, storeBar, storeVideo} from './storelocalstorage';

// import React from 'react';
import axios from 'axios'; 

export function checkSub() {

    var sds
    
    if (localStorage.getItem('uid')) {
    var suburl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + localStorage.getItem('email')

    axios.get(suburl).then(response => {

      console.log('subscribe in Renderdown', response.data.Item)
      localStorage.setItem('cn', response.data.Item.count.S)
      sds = true

    // if the item doesnt exist in subscribe table
    }).catch(e => {
      localStorage.setItem('cn', '0')
      sds = false
    })

    console.log('subscribe in Renderdown', localStorage.getItem('cn'))
  }


  return sds
}


export function deleteText(e) {
  // var y = e.target.parentNode.classList.contains("delH") || e.target.parentNode.parentNode.classList.contains("delH")
  
  console.log('deleteText', e.currentTarget, e.currentTarget.parentNode)
  var y ;
  if ( e.currentTarget.parentNode.classList.contains("delH") ) {
    y = e.currentTarget.parentNode
  } else {
    y = e.currentTarget.parentNode.parentNode
  }

  var z = y.id
  console.log('y', y, z.split('z')[0])
  document.getElementById(z.split('z')[0]).remove()
  y.remove()

  // document.getElementsByClassName("heads-text-container")[0].remove()
  // document.getElementsByClassName("delH")[0].remove()

}







export const addHead = function(w) {

  var xh = JSON.parse(localStorage.getItem(w.toString()))

  var pa = document.getElementById("vid")

// add container on video
  var div = document.createElement("div");
  var i= Date.now()
  var id = 'text' + i.toString()
  div.setAttribute('id', id)
  div.setAttribute('class', 'heads-text-container')
  pa.append(div)
  div.style.left = xh[3];
  // div.style.right = 0 + '%';
  div.style.top = xh[4];
  // div.style.margin = 'auto';
  div.style.width = xh[2];
  div.style.backgroundColor = xh[1];
  div.style.justifyContent = xh[5];

  // div.classList.add("move")

  $(".heads-text-container").draggable({
       stop: function () {
            console.log('ui', $(this).position(), ( 100 * parseFloat($(this).css("width")) / $(this).parent().width() ) );
        var l = ( 100 * parseFloat($(this).position().left / parseFloat($(this).parent().width())) ) + "%" ;
        var t = ( 100 * parseFloat($(this).position().top / parseFloat($(this).parent().height())) ) + "%" ;
        var w = ( 100 * parseFloat($(this).css("width")) / $(this).parent().width() ) + "%" ;
        var b = ( 100 * parseFloat($(this).css("bottom")) / $(this).parent().height() ) + "%" ;
        $(this).css("left", l);
        $(this).css("top", t);
        $(this).css("width", w);
        $(this).css("bottom", b);
    }
  });
  // $(".heads-text-container").resizable({ 
  //  // handles: 'all',
  //  handles: 'e, w, ne, se, sw, nw',
  //  aspectRatio: true,
  //  stop: function () {
  //           console.log('ui', $(this).position(), ( 100 * parseFloat($(this).css("width")) / $(this).parent().width() ) );
  //       var l = ( 100 * parseFloat($(this).position().left / parseFloat($(this).parent().width())) ) + "%" ;
  //       var t = ( 100 * parseFloat($(this).position().top / parseFloat($(this).parent().height())) ) + "%" ;
  //       var w = ( 100 * parseFloat($(this).css("width")) / $(this).parent().width() ) + "%" ;
  //       var b = ( 100 * parseFloat($(this).css("bottom")) / $(this).parent().height() ) + "%" ;
  //       $(this).css("left", l);
  //       $(this).css("top", t);
  //       $(this).css("width", w);
  //       $(this).css("bottom", b);
  //   }
  //  });
  $(".heads-text-container").find("div.ui-resizable-se").removeClass("ui-icon");
  $(".heads-text-container").find("div.ui-resizable-se").removeClass("ui-icon-gripsmall-diagonal-se");

  // var texts = document.getElementsByClassName("heads-text-container")

  div.addEventListener('click', function(e){

    e.preventDefault();
    e.stopPropagation();

    var texts = document.getElementsByClassName("move")
    for (var i = 0; i < texts.length; i++) {
        texts[i].classList.remove("move");
    }

    console.log('move', e.target, e.currentTarget)
    e.currentTarget.classList.add("move")

    // document.getElementById("subtitles-text-container").classList.remove("move")      
    // document.getElementById("custom-seekbar1").classList.remove("move")
    // document.getElementById("vx").classList.remove("move")

    showTextstyle();
  });
  

  var span = document.createElement("SPAN");
  span.setAttribute('dir', 'ltr')
  span.setAttribute('class', 'head-text-container')
  div.append(span)

  span.style.color = xh[0]
  span.style.textAlign = xh[6];
  span.style.fontWeight = xh[7]
  span.style.fontStyle = xh[8]
  span.style.fontFamily = xh[9]
  // span.innerText = xh[10]
  span.innerHTML = xh[11]

  span.style.fontSize = document.getElementsByClassName("fontsize")[0].children[0].children[0].textContent + 'px';

// // head.style.padding = head_pad_new + 'px'
// span.style.fontSize = head_fs_new + 'px'  //16px
// span.style.fontFamily = 'Oswald'; 


// add container to edit the text
  pa = document.getElementById("textstylx")
  div = document.createElement("div");
  div.setAttribute('id', id+'z')
  div.setAttribute('class', 'delH')
  pa.append(div)

  var div1 = document.createElement("div");
  div1.addEventListener("click", (e) => {deleteText(e) })
  // onClick={deleteText} 

  div1.innerHTML = `<li class='fa del'>&#xf1f8</li>`
  // div1.innerHTML = `<i class="fa fa-close"></i>`
  div.append(div1)


  var div2 = document.createElement("div");
  div2.setAttribute('class', 'xheadx')
  div.append(div2)

  span = document.createElement("textarea");
  span.setAttribute('placeholder', 'Enter Text Here');
  span.setAttribute('class', 'head')
  div2.append(span)

  span.setAttribute('contentEditable',"true");
  // span.value = xh[10]
  // span.innerHTML = xh[11]
  span.value = xh[12]

// span.style.padding = head_pad_new + 'px';

  span.addEventListener("focus", function(e) {
    e.stopPropagation();
    e.preventDefault();
    console.log('click click')

  var texts = document.getElementsByClassName("move")
  for (var i = 0; i < texts.length; i++) {
        texts[i].classList.remove("move");
    }

  var z = e.target.parentNode.parentNode.id.split('z')[0]
  document.getElementById(z).classList.add("move")
  console.log('focus', z, document.getElementById(z))

  })

  span.addEventListener("keyup", function(e) {
    e.stopPropagation();
    e.preventDefault();
  var z = e.target.parentNode.parentNode.id.split('z')[0]
  document.getElementById(z).getElementsByTagName("SPAN")[0].innerText = e.target.value; 

})




}


export const showProgress = function () {
  document.getElementsByClassName("clickedV")[0].setAttribute("class", "btn btn-default")
  document.getElementById("showProgress").setAttribute("class", "clickedV btn btn-default")

  document.getElementById("progressBar").style.display = 'block'

  document.getElementById("textstyle").style.display = 'none'
  document.getElementById("showVideo").style.display = 'none'
  document.getElementById("substyle").style.display = 'none'

  document.getElementById("dcolumn").style.display = 'none';
  document.getElementById("askSub").style.display = 'none';


}


export const showVideo = function () {

  document.getElementsByClassName("clickedV")[0].setAttribute("class", "btn btn-default")
  document.getElementById("videoEdit").setAttribute("class", "clickedV btn btn-default")

  document.getElementById("showVideo").style.display = 'block'
  // document.getElementById("progressBar").style.display = 'none'
  document.getElementById("textstyle").style.display = 'none'

  document.getElementById("substyle").style.display = 'none'
  document.getElementById("dcolumn").style.display = 'none';
  document.getElementById("askSub").style.display = 'none';
    

}

export const showSubstyle = function () {

  document.getElementsByClassName("clickedV")[0].setAttribute("class", "btn btn-default")
  document.getElementById("showSubstyle").setAttribute("class", "clickedV btn btn-default")

  document.getElementById("substyle").style.display = 'block'
    // document.getElementById("progressBar").style.display = 'none'
    document.getElementById("textstyle").style.display = 'none'
    document.getElementById("showVideo").style.display = 'none'
    
    document.getElementById("dcolumn").style.display = 'none';
    document.getElementById("askSub").style.display = 'none';

}

export const showTextstyle = function () {

  console.log("in showTextstyle")

  document.getElementsByClassName("clickedV")[0].setAttribute("class", "btn btn-default")
  document.getElementById("showTextstyle").setAttribute("class", "clickedV btn btn-default")

  document.getElementById("textstyle").style.display = 'block';
    // document.getElementById("progressBar").style.display = 'none'

    document.getElementById("showVideo").style.display = 'none'
    document.getElementById("substyle").style.display = 'none'
        document.getElementById("dcolumn").style.display = 'none';
        document.getElementById("askSub").style.display = 'none';

}

export const askSubt = function() {

  document.getElementsByClassName("clickedV")[0].setAttribute("class", "btn btn-default")
  document.getElementById("askSubt").setAttribute("class", "clickedV btn btn-default")



    document.getElementById("textstyle").style.display = 'none';
    // document.getElementById("progressBar").style.display = 'none'

    document.getElementById("showVideo").style.display = 'none'
    document.getElementById("substyle").style.display = 'none'
 
    // var x = document.getElementById("askSub").getAttribute("x")
    var x = localStorage.getItem("showSub")
    // "0" => subtitles haven't been generated yet
    if ( x == "0") {
        document.getElementById("askSub").style.display = 'flex'
        document.getElementById("dcolumn").style.display = 'none';
        document.getElementById("showSub").style.display = 'none';
} else {
        document.getElementById("askSub").style.display = 'none';
        document.getElementById("dcolumn").style.display = 'block';
        document.getElementById("showSub").style.display = 'block';
}

}

// moved to canvastry.js
// export const keytimer = function(e) {
//   // highlight sub + vid current time + change video text
//   console.log('in keytimer');

//     // if (e.target.classList.contains('caption')) {
//     if (window.getSelection() && window.getSelection().rangeCount) {
      
//       var select = window.getSelection();
//       console.log('select', e.target.innerText);

//       var range = select.getRangeAt(0);

//       if (range.startContainer.parentNode.tagName == "SPAN" && (range.startContainer.parentNode.getAttribute('class') == "caption" || range.startContainer.parentNode.getAttribute('class') == "caption current-caption")) {

//       var vid = document.getElementById("vid1");
//       if (vid.play) {
//       // // console.log('hello');
//       vid.pause();
//         var play = document.getElementById("play");
//         var pause = document.getElementById("pause");

//         play.style.display='inline-block';
//         pause.style.display='none';
//     }

//     var zz = window.getSelection().getRangeAt(0).startContainer.parentNode;
//     var idn = zz.id;

    
//     // var startTime = props.state.strt;
//     var vid = document.getElementById("vid1"); 

//     var cursorPos = window.getSelection().getRangeAt(0).startOffset; 
//     console.log('keytimer time', e.target,'idn', idn,'curpos', cursorPos)
//     var t = gettime(idn, cursorPos);
//     console.log("t", t);
//     vid.currentTime = t/1000;

//     if (document.getElementsByClassName("current-caption")[0]) {
//     var current = document.getElementsByClassName("current-caption")[0]
 
//     current.classList.remove("current-caption");

//   }

//   zz.classList.add("current-caption") 
//   // setAttribute("className", "caption current-caption");
//   // zz.setAttribute("class", "caption current-caption");


//     var tx = zz.innerText; // textContent

//     // not single, there could be multiple spaces!
//     // length will be 0 if empty string 
//     if (tx.replace(/\s/g, '').length) {
//             // var vi = document.getElementById("subtitles-text-container");
//             // vi.style.visibility="visible";  

//         }
//     // zz.innerText = tx;
//     // props.changeSub(newsub)
//    // props.changeCaptions(tx)
//    document.getElementById("subtitle-text-container").innerText = zz.innerText;
//     // props.changeKey(1);
//     console.log('@@@@@ #### BEFORE storeSub\n', localStorage.getItem('subx'))
//   storeSub();

    
//   }      
// }
// }


export const moveElement = function(e) {
  console.log('before try movelement', document.getElementsByClassName("move")[0])
  
var key = e.keyCode == 37 || e.keyCode == 38 || e.keyCode == 39 || e.keyCode == 40   
if(!key) return;

try {
  var t = document.getElementsByClassName("move")[0]
  if(t.id == "vx") return;
  // console.log('moveElement', e.keyCode, t.style.bottom,t.style.left)

  var v;
  if(e.keyCode == 37) { //left
    if(t.id == "subtitles-text-container") return;
    if(t.id == "custom-seekbar1") return;
     v = 1
  }
  
  if(e.keyCode == 39) { //right
     if(t.id == "subtitles-text-container") return;
     if(t.id == "custom-seekbar1") return;
     v = -1
  }

  var k = e.keyCode == 37 || e.keyCode == 39

  if(k) { 
  if(t.style.left ) {
    t.style.left = parseInt(t.style.left) - v +'%';
  } else {
    var dleft = 100* t.offsetLeft/document.getElementById("vid").offsetWidth
    t.style.left = dleft - v + '%';
}

  storeBar();
  // storeSubstyle();

  return;
}

  if(e.keyCode == 38) { //up
        v =  1  
    if(parseInt(document.getElementById("custom-seekbar1").style.top) < 1) return;
      } 

  if(e.keyCode == 40) { //down
        v = - 1

// in %
    var tc = parseFloat(document.getElementById("custom-seekbar1").style.top)
    var hc =parseFloat(document.getElementById("custom-seekbar1").style.height)*100/parseFloat(document.getElementById("stage").getBoundingClientRect().height )
    console.log('100-hc-tc', 100-tc-hc)
    
    if(100-hc-tc  < 1) return;
    }
  


if(t.id == "subtitles-text-container") {

  if (t.style.bottom) {
      t.style.bottom = parseInt(t.style.bottom) + v + '%';
} else {
  var dbottom = 100* (document.getElementById("vid").offsetHeight - t.offsetHeight - t.offsetTop)/document.getElementById("vid").offsetHeight
  // console.log('dtop', dtop, v, t.style.top, t.style.bottom)
  t.style.bottom = dbottom + v + '%';
  // now t.style.top will start exisitng
}

} else {

  if (t.style.top) {
      t.style.top = parseInt(t.style.top) - v + '%';
} else {
  var dtop = 100* t.offsetTop/document.getElementById("vid").offsetHeight
  // console.log('dtop', dtop, v, t.style.top, t.style.bottom)
  t.style.top = dtop - v + '%';
  // now t.style.top will start exisitng
}
}

  storeBar();
  // storeSubstyle();


} catch {}

}



export const setDim = function(r, props) {

var vcx = document.getElementById("stage") 
var vx = document.getElementById("vid1")
var canx = document.getElementById("canvas2")
vx.style.height = '100%';
vx.style.width = '100%';


var vidx = document.getElementById("vx");

var h = localStorage.getItem('height')
var w = localStorage.getItem('width')

var hh, ww
// vcx.style.maxWidth = '300vw';
// vidx.style.maxWidth = '300vw';
// vcx.style.maxHeight = '300vh';
// vidx.style.maxHeight = '300vh';

if (r == 'org') {
  r = (parseFloat(w)/parseFloat(h)).toFixed(1);
}

console.log('rrrr', r, parseFloat(h)/parseFloat(w))

if (r == '9:16' || r == '0.6') {
  
vcx.style.width = 'calc(74.3vh * 9 / 16)';
vcx.style.height = '74.3vh';

hh = vcx.style.height
ww = parseFloat(vcx.style.height)*parseFloat(w)/parseFloat(h) + "vh"


if ( parseFloat(ww) > parseFloat(hh)*(9/16)) {
    vidx.style.width = parseFloat(vcx.style.height)*(9/16) + 'vh';
    vidx.style.height = parseFloat(vcx.style.height)*(9/16)*parseFloat(h)/parseFloat(w) + "vh"
} else {
    vidx.style.width = "calc("+ ww +")";
    vidx.style.height = hh ;
}

canx.height = vcx.offsetHeight;
canx.width = vcx.offsetWidth ;



// vidx.style.width = vcx.style.width;
// var cg = parseFloat(vcx.style.height)*(9/16)*parseFloat(h)/parseFloat(w) + "vh";
// vidx.style.height = "calc("+ cg +")";

return;

}

if (r == '16:9' || r == "1.8") {
vcx.style.width = '38.9vw';
vcx.style.height = 'calc(38.9vw * 9 / 16)';

// var cg = parseFloat(vcx.style.width)*(9/16)*parseFloat(w)/parseFloat(h) + "vw";
// vidx.style.width = "calc("+ cg +")";
// vidx.style.height = vcx.style.height;

ww = vcx.style.width
hh = parseFloat(vcx.style.width)*parseFloat(h)/parseFloat(w) + "vw"

// calculated height > height of stage
if ( parseFloat(hh) > parseFloat(ww)*(9/16)) {
    vidx.style.height = parseFloat(vcx.style.width)*(9/16) + 'vw';
    vidx.style.width = parseFloat(vcx.style.width)*(9/16)*parseFloat(w)/parseFloat(h) + "vw"
} else {
    vidx.style.width = "calc("+ ww +")";
    vidx.style.height = hh ;
}

canx.height = vcx.offsetHeight;
canx.width = vcx.offsetWidth ;

return;

}

if (r == '1:1' || r == "1.0") {
vcx.style.width = '59.3vh';
vcx.style.height = '59.3vh';

// vidx.style.width = vcx.style.width;
// var cg = parseFloat(vcx.style.width)*parseFloat(h)/parseFloat(w) + "vh";
// vidx.style.height = "calc("+ cg +")";

hh = vcx.style.height
ww = parseFloat(vcx.style.height)*parseFloat(w)/parseFloat(h) + "vh"

// calculated width > width of stage
if ( parseFloat(ww) > parseFloat(hh)*(1/1)) {
    vidx.style.width = parseFloat(vcx.style.height)*(1/1) + 'vh';
    vidx.style.height = parseFloat(vcx.style.height)*(1/1)*parseFloat(h)/parseFloat(w) + "vh"
} else {
    vidx.style.width = "calc("+ ww +")";
    vidx.style.height = hh ;
}


canx.height = vcx.offsetHeight;
canx.width = vcx.offsetWidth ;

return;

}

if (r == '4:5' || r == "0.8") {
vcx.style.width = 'calc(66.45vh * 4 / 5)';
vcx.style.height = '66.45vh';
// vidx.style.width = vcx.style.width ;
// var cg = parseFloat(vcx.style.height)*(4/5)*parseFloat(h)/parseFloat(w) + 'vh';
// vidx.style.height = "calc("+ cg +")";


hh = vcx.style.height
ww = parseFloat(vcx.style.height)*parseFloat(w)/parseFloat(h) + "vh"

// calculated width > width of stage
if ( parseFloat(ww) > parseFloat(hh)*(4/5)) {
    vidx.style.width = parseFloat(hh)*(4/5) + 'vh';
    vidx.style.height = parseFloat(hh)*(4/5)*parseFloat(h)/parseFloat(w) + "vh"
} else {
    vidx.style.width = "calc("+ ww +")";
    vidx.style.height = hh ;
}

canx.height = vcx.offsetHeight;
canx.width = vcx.offsetWidth ;


return;

}

// if (r == 'org') {

// vcx.style.width = '33.65vw';
// var cg = parseFloat(vcx.style.width)*parseFloat(props.state.height)/parseFloat(props.state.width) + "vw";

// console.log('cggg', cg, r)

// vidx.style.width = '33.65vw';
// vcx.style.height = "calc("+ cg +")";
// vidx.style.height = "calc("+ cg +")";

// return;

// }

ww = '38.9vw'
vcx.style.width = ww
hh = parseFloat(vcx.style.width)*parseFloat(h)/parseFloat(w) + "vw";


// console.log('cggg', cg)
// if ( cg == 'NaNvw' ) { 
//   cg = '33.65vw' 
//   console.log('cggg2', cg)

  // vcx.style.maxWidth = '38.9vw';
  // vidx.style.maxWidth = '38.9vw';
  // vcx.style.maxHeight = '74.3vh'; //35.3vw

// vidx.style.height = "calc("+ cg +")";



// calculated stage height > max height 
if ( parseFloat(hh) > 35.3) {

    vcx.style.height = '35.3vw'
    vidx.style.height = '35.3vw'
    vcx.style.width = (35.3)*parseFloat(w)/parseFloat(h) + 'vw';
    vidx.style.width = (35.3)*parseFloat(w)/parseFloat(h) + "vw";
} else {
    vcx.style.height = "calc("+ hh +")";
    vidx.style.height = "calc("+ hh +")";
    vcx.style.width = "calc("+ ww +")";
    vidx.style.width = "calc("+ ww +")";
}

canx.height = vcx.offsetHeight;
canx.width = vcx.offsetWidth ;


return;
// } 

}


// set width of head and sub container
// export const setWidth = function(x) {

//     if ( x == 1) {
//   var ch = document.getElementById('head-text-container');
//   var chead = document.getElementById('heads-text-container');
//   } else {

//     var ch = document.getElementById('subtitle-text-container');
//     var chead = document.getElementById('subtitles-text-container');
//   }

//     var style = window.getComputedStyle(ch);

//     var padding_left = parseFloat(style.getPropertyValue("padding-left"));
//     var padding_right = parseFloat(style.getPropertyValue("padding-right"));
   
//     var border_left = parseFloat(style.getPropertyValue("border-left-width"));
//     var border_right = parseFloat(style.getPropertyValue("border-right-width"));

//     var cS = ch.cloneNode();
//     cS.style.visibility = "hidden";
//     cS.setAttribute('id', 'pooo')
//     cS.setAttribute('class', 'pooo')
//     cS.textContent = chead.textContent;
//     console.log('text', chead.textContent);
//     cS.style.whiteSpace = 'nowrap';
//     chead.appendChild(cS);
//     console.log('wid span', $('#pooo').width(),cS.offsetWidth);
    
    
//     var ff = cS.offsetWidth
//     chead.removeChild(cS)
    
//     ff = ff / Math.ceil(ff / $('#vid').width());
//     console.log('ff',ff)
//     ff = 10 + ff + padding_left + padding_right + border_left + border_right
//     // ff = ff;
//     if (ff > ($('#vid').width() - 10)) {
//        ff = $('#vid').width() - 10;
//     }

//     // chead.style.width = ff +'px';
//     ch.style.width = ff +'px';

//     console.log('vid1 offsetTop', document.getElementById("vid1").offsetTop, document.getElementById("heads-text-container").offsetHeight)

//     console.log('wid hi',ff,$('#vid').width())
// }

export const tickerTime = function(x) {

  var sp1 = x.split(':');

  return parseInt(sp1[0])*60*1000 + parseInt(sp1[1])*1000 + parseInt(sp1[2])*100;

}



// export const strToMili = function(x) {
//   // console.log('in strToMili', x);
//   // console.log(x, typeof(x))

//    var sp1 = x.split(':');

//       if (sp1.length == 1) {

//         var t1 = parseInt(sp1[0])*60*1000 ;
//       } else {

//         // var s = parseFloat(sp1[1]).toString().split(".");


//         var s = parseFloat(sp1[1]).toFixed(3).split(".");
//         // // console.log('strToMili', s);
//         if (s.length == 1) {
//           var t1 = parseInt(sp1[0])*60*1000 + parseInt(s[0])*1000;
//         } else {
//         // var t1 = parseInt(sp1[0])*60*1000 + parseInt(s[0])*1000 + parseInt(s[1])*100;
//         var t1 = parseInt(sp1[0])*60*1000 + parseInt(s[0])*1000 + parseInt(s[1]);
//       } 
//     }

//   return t1
// }


export const strToMili = function(x) {
  // console.log('in strToMili', x);
  // console.log(x, typeof(x))

   var sp1 = x.split(':');

   if (sp1.length == 1) {

        var t1 = parseInt(sp1[0])*60*1000 ;
      } 

        // var s = parseFloat(sp1[1]).toString().split(".");
    if(sp1.length == 2) {

        var s = parseFloat(sp1[1]).toFixed(3).split(".");
        // // console.log('strToMili', s);
        if (s.length == 1) {
          var t1 = parseInt(sp1[0])*60*1000 + parseInt(s[0])*1000;
        } else {
        // var t1 = parseInt(sp1[0])*60*1000 + parseInt(s[0])*1000 + parseInt(s[1])*100;
        var t1 = parseInt(sp1[0])*60*1000 + parseInt(s[0])*1000 + parseInt(s[1]);
      }
   }

    if(sp1.length == 3) {


        var s = parseFloat(sp1[2]).toFixed(3).split(".");
        // // console.log('strToMili', s);
        if (s.length == 1) {
          var t1 = parseInt(sp1[0])*3600*1000 + parseInt(sp1[1])*60*1000 + parseInt(s[0])*1000;
        } else {
        // var t1 = parseInt(sp1[0])*60*1000 + parseInt(s[0])*1000 + parseInt(s[1])*100;
        var t1 = parseInt(sp1[0])*3600*1000 + parseInt(sp1[1])*60*1000 + parseInt(s[0])*1000 + parseInt(s[1]);
      }


    }

    

  return t1
}

export const miliToTime = function(duration) {
  // duration in miliseconds

console.log(duration);
  var milliseconds = parseInt((duration%1000))
    , seconds = parseInt((duration/1000)%60)
    , minutes = parseInt((duration/(1000*60))%60)
    , hours = parseInt((duration/(1000*60*60))%24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  milliseconds = (milliseconds/1000).toFixed(1).split('.')[1];


  if (hours == "00") {
    if (minutes == "00") {
      return seconds + "." + milliseconds;
    }
    else return minutes + ":" + seconds + "." + milliseconds;
  }

  else {
    return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
  }

}




export const miliToMinTime = function(duration) {
  // duration in miliseconds

console.log(duration);
  var milliseconds = parseInt((duration%1000))
    , seconds = parseInt((duration/1000)%60)
    , minutes = parseInt((duration/(1000*60))%60)
    , hours = parseInt((duration/(1000*60*60))%24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  milliseconds = (milliseconds/1000).toFixed(1).split('.')[1];

  if (hours == "00") {
    return minutes + ":" + seconds + "." + milliseconds;
  }

  else {
    return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
  }

}



export const miliToHrTime = function(duration) {
  // duration in miliseconds

console.log(duration);
  var milliseconds = parseInt((duration%1000))
    , seconds = parseInt((duration/1000)%60)
    , minutes = parseInt((duration/(1000*60))%60)
    , hours = parseInt((duration/(1000*60*60))%24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  milliseconds = (milliseconds/1000).toFixed(1).split('.')[1];

  return hours + ":" + minutes + ":" + seconds + "." + milliseconds;

}

export const miliToHrCommaTime = function(duration) {
  // duration in miliseconds

console.log(duration);
  var milliseconds = parseInt((duration%1000))
    , seconds = parseInt((duration/1000)%60)
    , minutes = parseInt((duration/(1000*60))%60)
    , hours = parseInt((duration/(1000*60*60))%24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  milliseconds = (milliseconds/1000).toFixed(1).split('.')[1];

  return hours + ":" + minutes + ":" + seconds + "," + milliseconds;

}


export const getLowestIndex = function(array) {
  // console.log('getLowestIndex')
    var low = array[0],                   // take the first element at index 0
        i, index=0;


    console.log('ingetlow', 'diff:',array)
    for (i = 1; i < array.length; i++) { // start with index 1, because you need to
                                         // check against the last known smallest value
        if(array[i] < low) {
            low = array[i];
            index = i;

            console.log('index', index, 'arr i', array[i]);
        }
    }
    console.log('index', index);
    return index;
}


//calculate time at current cursor position
export const gettime = function(idn, cursorPos) {
  console.log('in get time')
  var pa_stime = document.getElementById(idn).getAttribute("stime");
  var pa_etime = document.getElementById(idn).getAttribute("etime");

  console.log(document.getElementById(idn))
  var t1 = strToMili(pa_stime);
  var v = document.getElementById(idn).innerText;
  console.log('v',v,'len', v.length);

  if (v.length == 0) {return t1;} //if not text 
  // console.log('parent node', document.getElementById(idn));
  // console.log('strt',strt);
   console.log('t1', t1);
    var t2 = strToMili(pa_etime);
  console.log('t2', t2);

    var y = (t2 - t1)*cursorPos/v.length;
    // console.log('t2',t2,'t1',t1, 'cur', cursorPos/v.length, 'y', y)
    y = y + t1;
    console.log('curp', cursorPos,'v', v.length,'y', y);
    return y;
}

export const getAbcString = function(x) {

      console.log('x',x)

      var a = x.split(':')[0].padStart(2,0);
      var b = x.split(':')[1].split('.')[0].padStart(2,0);
      var c = x.split(':')[1].split('.')[1].padStart(1,0);

      return [a,b,c];

}


export const setTickerState = function(id) {

  var rowT = document.getElementById("rows")
  var getId = id;
  var MaxTime = document.getElementById(getId).getAttribute("etime");
  if(id == 0) {
    var MinTime = "00:00.0"
  } else {
  var MinTime = document.getElementById(getId).previousSibling.previousSibling.getAttribute("stime");
  }

  var timCurrent = document.getElementById(getId).getAttribute("stime");
  
//max  [ "00", "03", "4" ]
//min     "00"  "00"  "0"
//timC   "00" "00" "9"

var max = getAbcString(MaxTime);
var min = getAbcString(MinTime);
var timC = getAbcString(timCurrent);

var z = document.getElementById("zero").childNodes
var o = document.getElementById("one").childNodes
var t = document.getElementById("two").childNodes

// if [1] is still less, [2] ll automatically be black
// if [1] is grey, [2] can or not be grey ([2] ll be compared to [2] of max)
// if [0] is still less, all allowed
// if [0] is grey, check [1] ([1] ll be compared to [1] of max)

// when the ticker opens
if (timC[0] < max[0]) {  //if tim is less than max time
  z[0].setAttribute("class", "MuiSvgIcon-root up MuiSvgIcon-colorSecondary" )
  z[1].style.color = "black";

  o[0].setAttribute("class", "MuiSvgIcon-root up MuiSvgIcon-colorSecondary" )
  o[1].style.color = "black";

  t[0].setAttribute("class", "MuiSvgIcon-root up MuiSvgIcon-colorSecondary" )
  t[1].style.color = "black";
} else {
  // min is >= allowed limited
  z[0].setAttribute("class", "MuiSvgIcon-root up MuiSvgIcon-colorDisabled");

  if (timC[1] < max[1]) {  //if tim is less than max time
      o[0].setAttribute("class", "MuiSvgIcon-root up MuiSvgIcon-colorSecondary" )
      o[1].style.color = "black";

      t[0].setAttribute("class", "MuiSvgIcon-root up MuiSvgIcon-colorSecondary" )
      t[1].style.color = "black";
    } else {

      o[0].setAttribute("class", "MuiSvgIcon-root up MuiSvgIcon-colorDisabled" );

      if (timC[2] < max[2]) {
        t[0].setAttribute("class", "MuiSvgIcon-root up MuiSvgIcon-colorSecondary");
      } else {
        t[0].setAttribute("class", "MuiSvgIcon-root up MuiSvgIcon-colorDisabled" )
      }
    }
}
     
 
if (timC[0] > min[0]) {  //if tim is less than max time
  z[2].setAttribute("class", "MuiSvgIcon-root down MuiSvgIcon-colorSecondary" )
  o[2].setAttribute("class", "MuiSvgIcon-root down MuiSvgIcon-colorSecondary" )
  t[2].setAttribute("class", "MuiSvgIcon-root down MuiSvgIcon-colorSecondary" )
} else {
  // min is >= allowed limited
  z[2].setAttribute("class", "MuiSvgIcon-root down MuiSvgIcon-colorDisabled");

  if (timC[1] > min[1]) {  //if tim is less than max time
      o[2].setAttribute("class", "MuiSvgIcon-root down MuiSvgIcon-colorSecondary" );
      t[2].setAttribute("class", "MuiSvgIcon-root down MuiSvgIcon-colorSecondary" );
    } else {

      o[2].setAttribute("class", "MuiSvgIcon-root down MuiSvgIcon-colorDisabled" );

      if (timC[2] > min[2]) {
        t[2].setAttribute("class", "MuiSvgIcon-root down MuiSvgIcon-colorSecondary" )
      } else {
        t[2].setAttribute("class", "MuiSvgIcon-root down MuiSvgIcon-colorDisabled" )
      }
    }
}
         
// on click +1 or -1 

      var MaxNew = strToMili(MaxTime);
      var MinNew = strToMili(MinTime);
      var timNew = strToMili(timCurrent);
      
      if (timNew + 60*1000 > MaxNew) {
        z[0].setAttribute("class","MuiSvgIcon-root up MuiSvgIcon-colorDisabled" )
      } else {
        z[0].setAttribute("class","MuiSvgIcon-root up MuiSvgIcon-colorSecondary" )
      }

      if (timNew + 1000 > MaxNew){
        o[0].setAttribute("class","MuiSvgIcon-root up MuiSvgIcon-colorDisabled" )
      } else {
        o[0].setAttribute("class", "MuiSvgIcon-root up MuiSvgIcon-colorSecondary" )
      }   

      if (timNew + 100 > MaxNew) {
        t[0].setAttribute("class", "MuiSvgIcon-root up MuiSvgIcon-colorDisabled" )
      } else {
        t[0].setAttribute("class", "MuiSvgIcon-root up MuiSvgIcon-colorSecondary" )
      }



    if (timNew - 60*1000 < MinNew) {
        z[2].setAttribute("class","MuiSvgIcon-root down MuiSvgIcon-colorDisabled" )
      } else {
        z[2].setAttribute("class","MuiSvgIcon-root down MuiSvgIcon-colorSecondary" )
      }

      if (timNew - 1000 < MinNew){
        o[2].setAttribute("class","MuiSvgIcon-root down MuiSvgIcon-colorDisabled" )
      } else {
        o[2].setAttribute("class", "MuiSvgIcon-root down MuiSvgIcon-colorSecondary" )
      }   

      if (timNew - 100 < MinNew) {
        t[2].setAttribute("class", "MuiSvgIcon-root down MuiSvgIcon-colorDisabled" )
      } else {
        t[2].setAttribute("class", "MuiSvgIcon-root down MuiSvgIcon-colorSecondary" )
      }


// color

if (z[0].classList.contains('MuiSvgIcon-colorDisabled') && z[2].classList.contains('MuiSvgIcon-colorDisabled')) {
    z[1].style.color = "darkgrey";
} else {
    z[1].style.color = "black";
}


if (o[0].classList.contains('MuiSvgIcon-colorDisabled') && o[2].classList.contains('MuiSvgIcon-colorDisabled')) {
    o[1].style.color = "darkgrey";
} else {
    o[1].style.color = "black";
}


if (t[0].classList.contains('MuiSvgIcon-colorDisabled') && t[2].classList.contains('MuiSvgIcon-colorDisabled')) {
    t[1].style.color = "darkgrey";
} else {
    t[1].style.color = "black";
}

// store subtitles, stime, etime in localstorage
  storeSub()

}

//  COLORS

function trim (str) {
  return str.replace(/^\s+|\s+$/gm,'');
}

export const rgbaToHex = function(rgba) {
  var inParts = rgba.substring(rgba.indexOf("(")).split(","),
      r = parseInt(trim(inParts[0].substring(1)), 10),
      g = parseInt(trim(inParts[1]), 10),
      b = parseInt(trim(inParts[2]), 10),
      a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
  var outParts = [
    r.toString(16),
    g.toString(16),
    b.toString(16),
    Math.round(a * 255).toString(16).substring(0, 2)
  ];

  // Pad single-digit output values
  outParts.forEach(function (part, i) {
    if (part.length === 1) {
      outParts[i] = '0' + part;
    }
  })

  return (outParts.join(''));
}


export const rgbToHex = function(r, g, b) {
  return ((1 << 24) + (Number(r) << 16) + (Number(g) << 8) + Number(b)).toString(16).slice(1);
}

export const hexToRgb = function( colour ) {
    var r,g,b;
    if ( colour.charAt(0) == '#' ) {
        colour = colour.substr(1);
    }

    if ( colour.length == 3 ) {
            colour = colour.substr(0,1) + colour.substr(0,1) + colour.substr(1,2) + colour.substr(1,2) + colour.substr(2,3) + colour.substr(2,3);
    }
    r = colour.charAt(0) + '' + colour.charAt(1);
    g = colour.charAt(2) + '' + colour.charAt(3);
    b = colour.charAt(4) + '' + colour.charAt(5);
    r = parseInt( r,16 );
    g = parseInt( g,16 );
    b = parseInt( b ,16);

    if (isNaN(r) || isNaN(g) || isNaN(b)) {
      return 2;
    }
    return [r,g,b];
}


 // * Assumes r, g, and b are contained in the set [0, 255] and
 // * returns h, s, and v in the set [0, 1].
export const rgbToHsv = function(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;

  var max = Math.max(r, g, b), min = Math.min(r, g, b);
  var h, s, v = max;

  var d = max - min;
  s = max == 0 ? 0 : d / max;

  if (max == min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }

    h /= 6;
  }

  return [ h, s, v ];
}
export const getHsl = function(h,x,y) {

h = parseFloat(h);
x = parseFloat(x);
y = parseFloat(y);

var hue = 360 * (h / 100) ;

console.log('hsl',h,'hue', hue,'x', x,'y', y);

var hsv_value = 1 - (y / 100);
var hsv_saturation = x / 100;
var lightness = (hsv_value / 2) * (2 - hsv_saturation);
console.log('hsl', hsv_value, hsv_saturation, lightness);
var saturation = (hsv_value * hsv_saturation) / (1 - Math.abs(2 * lightness - 1));
console.log('hsl', saturation);
return [hue, saturation, lightness]

}


export const hslToRgb = function(hsl) {

  var h  = hsl[0];
  h = h / 360 ;
  var s  = hsl[1];
  var l  = hsl[2];

  console.log('h-s-l in r-g-b', h,s,l);

  var r, g, b;

  if (s == 0) {
    r = g = b = l; // achromatic
  } else {
    function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1/6) return p + (q - p) * 6 * t;
      if (t < 1/2) return q;
      if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
      return p;
    }

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;

    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }


  return [
     Math.max(Math.min(r * 256, 255), 0)
    ,Math.max(Math.min(g * 256, 255), 0)
    ,Math.max(Math.min(b * 256, 255), 0) 
  ];
}


export const hslToHex = function(hsl) {

  var h  = hsl[0];
  var s  = hsl[1]*100;
  var l  = hsl[2]*100;

  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = x => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `${toHex(r)}${toHex(g)}${toHex(b)}`;
}



var gradient = [
    [
        0, //red
        [255,0,0]
    ],
    [
        17, //yellow
        [255,255,0]
    ],
    [
        33, //green
        [0,255,0]
    ],
    [
        50, //cyan
        [0,255,255]
    ],
    [
        67,
        [0,0,255]
    ],
    [
        83,
        [255,0,255]
    ],
    [
        100,
        [255,0,0]
    ]
];

function pickHex(color1, color2, weight) {
    var p = weight;
    var w = p * 2 - 1;
    var w1 = (w/1+1) / 2;
    var w2 = 1 - w1;
    var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2)];
    return rgb;
}



export const setSaturationColor = function(percent) {

  var colorRange = []
    // console.log('%',percent);
  $.each(gradient, function( index, value ) {
      console.log('percent', percent);
      // if(ui.value<=value[0]) {
        if( parseInt(percent) <= value[0]) {
        console.log('grad', index, value[0], value[1])


          colorRange = [index-1,index]      
          return false;
      }
  });
  

  //Get the two closest colors
  try {
  var firstcolor = gradient[colorRange[0]][1];
} catch {
  console.log('catch percent', percent);
  var c = gradient[colorRange[1]][1];
  return pickHex( c,c, 1);
}
  var secondcolor = gradient[colorRange[1]][1];
  
  // console.log('colors', firstcolor, secondcolor)
  //Calculate ratio between the two closest colors
  var firstcolor_x = gradient[colorRange[0]][0];
  var secondcolor_x = gradient[colorRange[1]][0]-firstcolor_x;
  var slider_x = percent-firstcolor_x;
  var ratio = slider_x/secondcolor_x
  
  //Get the color with pickHex(thx, less.js's mix function!)
  var result = pickHex( secondcolor,firstcolor, ratio );
  return result;
}

export const setXY = function(rgb) {

  var hsv = rgbToHsv(rgb[0],rgb[1],rgb[2]);

  console.log('setXY',hsv);

  var y = (1 - hsv[2])*100;
  var x = hsv[1]*100;
  var h = hsv[0]*100;

// saturation
  var offset = document.getElementsByClassName("vc-sketch-saturation-wrap")[0].getBoundingClientRect();

    var totalWidth = offset.width;
    var l = ( x * totalWidth ) / 100;

    var totalHeight = offset.height;
    var t = ( y * totalHeight ) / 100;

    if ( parseFloat(x)>99) {
    x = 99;
  }
  if ( parseFloat(y)>99) {
    y = 99;
  }
// to move the pointer
    $(".vc-saturation-pointer").css("left", l);
    $(".vc-saturation-pointer").css("top", t);
// to store the correct x,y values
    $(".vc-saturation-circle").css("left", x);
    $(".vc-saturation-circle").css("top", y);

    console.log('in setXY:',$(".vc-saturation-pointer").css("left"),l)
    console.log('in setXY:',$(".vc-saturation-circle").css("left"),x)
// hue position
    var offset = document.getElementsByClassName("vc-hue vc-hue--horizontal")[0].getBoundingClientRect();  
    var totalWidth = offset.width;
    var l = ( h * totalWidth ) / 100;


    var result = setSaturationColor(h);
    // set the saturation chart color
    $(".vc-saturation").css("background", 'rgb('+result.join()+') none repeat scroll 0% 0%');
    // set the pointer position in hue bar
    $(".vc-hue-picker").css("margin-left", l);
    
    // store the hue value to be passed on
    $(".vc-hue-pointer").css("left", h);

}

export const setColor = function(d, parent, rgb, props) {

  console.log("SET COLOR", parent, d, rgb)

// text font color
  if (parent.classList.contains("tfg")) {

    if (d=="s") {
    // document.getElementById("subtitle-text-container").style.color = 'rgba('+rgb.join()+')'

    props.update_x(0, 'tc', 'rgba('+rgb.join()+')')

    props.invalidate(false);



    // storeSubstyle();
  } else {

    if(props.selectText !=0) props.update_x(props.selectText, 'tc', 'rgba('+rgb.join()+')')

    props.invalidate(false);

    // var mv = document.getElementsByClassName("move")[0]
    // if (mv && mv.classList.contains("heads-text-container")) {
    //     mv.getElementsByTagName("SPAN")[0].style.color = 'rgba('+rgb.join()+')'
    //   }
  }

}

// text back color
  if (parent.classList.contains("tbbg")) {

    if (d=="s") {
    // var a = document.getElementsByClassName("alpha")[1].textContent;
    // rgb.push(a)

    //  full
    // if(document.getElementsByClassName("wrap")[1].style.display == "none") {
    //   document.getElementById("subtitles-text-container").style.backgroundColor = 'rgba('+rgb.join()+')'
    // } else {
    //   document.getElementById("subtitle-text-container").style.backgroundColor = 'rgba('+rgb.join()+')'
    // }

    props.update_x(0, 'fill', 'rgba('+rgb.join()+')')

    props.update_x(0, 'a', parseFloat(rgb[3]))


    props.invalidate(false);
    // storeSubstyle();
  
  } else {

    if(props.selectText!=0) props.update_x(props.selectText, 'fill', 'rgba('+rgb.join()+')')
    props.invalidate(false);
  }
}

//  progress bar
  if (parent.classList.contains("fp")) {
    document.getElementById("custom-seekbar1").children[0].style.backgroundColor = 'rgba('+rgb.join()+')'

    storeBar();
  }

  if (parent.classList.contains("bp")) {
    document.getElementById("custom-seekbar1").style.backgroundColor = 'rgba('+rgb.join()+')'
  
      storeBar();

  }

  if (parent.classList.contains("v")) {
    document.getElementById("vid").style.backgroundColor = 'rgba('+rgb.join()+')'

    storeVideo()
  }

}
