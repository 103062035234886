import React from 'react';
// import {strToMili, rgbToHex} from './utils';
// import {trim, getrgb, gethex} from './utilsave.js';


// import CircularProgress from '@material-ui/core/CircularProgress';


import axios from 'axios'; 

/* global $ */

//  add multiple heads-text, their width, subtitle width, seekbar width
//  video position and dimensions!


// the shown video has logo => old url is being used
// during rendering buttons and videos are showing

//use useState to store the states,transfer it to state on page change

export function rendervid (xu) {

  localStorage.removeItem('render')
  console.log('RENDERVID props', xu)

  document.getElementById("downopt").style.display = 'none'
  document.getElementsByClassName("rdownload_")[0].style.display = 'block'
  
  // localStorage.removeItem('dvid')




var filename = localStorage.getItem("filename");
var subscribe = localStorage.getItem('cn')


var xout = Date.now() + '_' + xu.filename
var x = xu.ti
var urlsrt = xu.urlsrt
var vidsr = xu.vidsr_ + '&subscribe=1' + '&xout=' + xout ;
var urlx = 'https://cbrwnvuuf6.execute-api.us-west-2.amazonaws.com/default/check_output_video?key=' + xout
var dvid = 'https://d2wxuz9jomxjzh.cloudfront.net/' + xout + '?cachebuster=' + Date.now()

var t = xu.t

localStorage.setItem('dvid', dvid)


var filename = xu.filename
var xout =  xu.xout
var width = xu.width
var height = xu.height
var pc = xu.pc
var back_bar = xu.back_bar
var front_bar = xu.front_bar
var bar_height = xu.bar_height
var duration = xu.duration

var dvid = xu.dvid

var ti = JSON.parse(xu.ti)

// ////////////////////////////////////////////////////////////////////////


// no watermark
var url = 'https://zuy32xbxsg.execute-api.us-west-2.amazonaws.com/default/canvas_render?filein=' + filename + '&fileout=' + xout + '&width=' + width + '&height=' + height + '&pc=' + pc + '&back_bar=' + back_bar + '&front_bar=' + front_bar + '&bar_height=' + bar_height + '&duration=' + duration

console.log('url', url)

var t = parseFloat(localStorage.getItem('duration'))

var urlx = 'https://cbrwnvuuf6.execute-api.us-west-2.amazonaws.com/default/check_output_video?key=' + xout

axios.post(url, ti,{
    headers: {
            'Content-Type': 'application/json',
          },
    }).then((res) => { 
      console.log('SENT')


  // document.getElementsByClassName("styling")[0].style.display = 'flex'

// display downopt buttons 
// display none Styling

console.log('URL', dvid);
// a.setAttribute('download', res.data)
var a = document.createElement('a');
// a.target = '_blank';
a.href = dvid;
// a.style.display = 'none';
// document.getElementById('subdiv').appendChild(a); // Required for Firefox
a.click(); 


document.getElementById("downopt").style.display = 'flex'
document.getElementsByClassName("rdownload_")[0].style.display = 'none'

try { document.getElementById("downvid").src = dvid } catch {}


    }).catch((e) => {
      console.log('ERROR',e)


if (t > 60) { t = t*0.5*1000 }
else { t = t*1000 }

var vix = setInterval(() => {

    axios.get(urlx).then(res=> {
      console.log('VIDEO GENERATED NOW', res)


// a.setAttribute('download', res.data)
var a = document.createElement('a');
// a.target = '_blank';
a.href = dvid;
// a.style.display = 'none';
// document.getElementById('subdiv').appendChild(a); // Required for Firefox
a.click(); 

document.getElementById("downopt").style.display = 'flex'
document.getElementsByClassName("download_")[0].style.display = 'none'

clearInterval(vix)


    }).catch(err=> console.log('ERRORR! in setInterval', err)) //close urlx

}, t) //close interval


    })



localStorage.setItem('render', "1")
return dvid
 
}





 

