import axios from 'axios';

export const sendJsonToS3Export = function(filename) {
	    var fname = filename.split('.')[0] + '.json'
    var url = 'https://xplks8isk4.execute-api.us-west-2.amazonaws.com/default/json_to_s3?key=' + fname

    console.log('sending json to s3', url)
    // debugger
    axios.post(url, JSON.stringify(JSON.parse(JSON.stringify(localStorage))),{
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((res) => {
            console.log('sent POST JSON res', res)}).catch(err => {console.log('json post ERROR', err)})
}


const sendJsonToS3 = function() {
	    var fname = localStorage.getItem('filename').split('.')[0] + '.json'
    var url = 'https://xplks8isk4.execute-api.us-west-2.amazonaws.com/default/json_to_s3?key=' + fname

    console.log('sending json to s3', url)
    axios.post(url, JSON.stringify(JSON.parse(JSON.stringify(localStorage))),{
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((res) => {
            console.log('sent POST JSON res', res)}).catch(err => {console.log('json post ERROR', err)})
}


export const storeSub = function(e) {


var ch = document.getElementById("subdiv").children;

var subtitle = [];
var sub = [], en = [], strt = [];

// dont consider last child (SPAN) which is END 
for (var i=0; i<ch.length ; i++){
  let s = {}
  if (ch[i].tagName == "DIV") {continue;}

 strt.push(ch[i].getAttribute("stime"));
 sub.push(ch[i].innerText);
 en.push(ch[i].getAttribute("etime"));

}

localStorage.setItem('subx', JSON.stringify(sub));
localStorage.setItem('en', JSON.stringify(en));
localStorage.setItem('strt', JSON.stringify(strt));

console.log('@@@@@ #### storeSub\n', localStorage.getItem('subx'))


if(document.getElementById("an1").getAttribute("x") == "1") {
	localStorage.setItem('langx', JSON.stringify(sub))
} else {
	localStorage.setItem('transx', JSON.stringify(sub))
}




}



export const storeBar = function(l,t,w) {

//  ratio 
var r = document.getElementById("custom-seekbar11").getAttribute("ratio");
var x = JSON.parse(localStorage.getItem('seekbar'))

var seekh = 0

var bck = document.getElementById("custom-seekbar1").style.backgroundColor
// bck = gethex(bck)
var frnt = document.getElementById("custom-seekbar1").children[0].style.backgroundColor
// frnt = gethex(frnt)

//  height, width, position, front, back color
var seekw, ytx, lefty ;

// in %
var w = document.getElementById("custom-seekbar1").style.width
var l = document.getElementById("custom-seekbar1").style.left
var t = document.getElementById("custom-seekbar1").style.top

// if(w) {

seekw = w
ytx =  t
lefty = l;


var seek = []
// height width position fron back
seek.push(r,seekh, seekw, ytx, frnt, bck, lefty)

console.log('seek', seek)

localStorage.setItem('seekbar', JSON.stringify(seek));

console.log('store json in storeBar')
sendJsonToS3();

}


export const storeVideo = function() {
	localStorage.setItem('videoColor', document.getElementById("vid").style.backgroundColor)

	console.log('store json in storeVideo')
	sendJsonToS3();
}



export const storeBox = function(boxes) {

	console.log('STOREBOXXX!!!', boxes)

	var subs = document.getElementsByClassName("caption")
	

	// boxes[0].text = subs[0].innerText
	// boxes[0].html = subs[0].innerHTML.replace(/\n/g,'<br>').replace(/&nbsp/g,'').replace(/&nbsp;/g,'')
	
	for(var k=0; k<boxes.length; k++) {
		localStorage.setItem("box_" + k,JSON.stringify(boxes[k]))
	}

	localStorage.setItem('nBox', k)

	console.log('store json in storebox')


}



