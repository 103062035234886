
import axios from 'axios'; 
import {flask_multipart} from './flask-s3-multipart';
import { navigate } from "gatsby" ; 


function showVideo(e) {

document.getElementById("circleDown").style.display = 'block'
document.getElementById("works").style.display = 'none'


console.log('e.target', e.target, e.target.getAttribute('x'))

 
// get the json, push it to localstorage
var fname = e.target.src.split('.net/')[1].split('.')[0] + '.json'

console.log('fname', fname)
// 'https://d2wxuz9jomxjzh.cloudfront.net/' + fname

var url = 'https://la04iscxs9.execute-api.us-west-2.amazonaws.com/default/json_from_s3?key=' + fname

console.log('url', url)

axios.get(url).then((res) => {
   console.log('GET', res.data)
var x = JSON.parse(res.data)

console.log('X',x)
var ks = Object.keys(x)
console.log('Keys',ks)

var uid = localStorage.getItem("uid")
var pic = localStorage.getItem("pic")
var subscribe = localStorage.getItem("cn")
var email = localStorage.getItem("email")
var login = localStorage.getItem("login")

localStorage.clear()


if(uid) localStorage.setItem("uid", uid)
if(pic) localStorage.setItem("pic", pic)
if(subscribe) localStorage.setItem("cn", subscribe)
if(email) localStorage.setItem('email', email)
if(login) localStorage.setItem('login', '1')

for (var i=0; i<ks.length; i++) {

    localStorage.setItem(ks[i], x[ks[i]])
}


var u = localStorage.getItem('uuid')

// document.getElementById("circleDown").style.display = 'none'
// document.getElementById("works").style.display = 'block'

// this.props.history.push({
//     pathname: `/vidx/${u}`,
//     state: { vid: x }
// })
const namef = fname.split('.')[0] 
// + '-' + fname.split('.')[1]

navigate(`/vidx/?v=${namef}`, {
  state: { vid: x }
})





}).catch(err => {

   document.getElementById("circleDown").style.display = 'none'
   document.getElementById("works").style.display = 'block'
   
   console.log('json GET ERROR', err)

})



}


export function load_workspace(props) {


// show workspace only once user is logged in 
        document.getElementById('works').style.display = 'block'


// set render

    document.getElementById("circleDown").style.display = 'block'

    flask_multipart(props, document.getElementById('file-workspace'))

   // var c = 1;
   // if(localStorage.getItem('subscribe') == '1') {c = 11;}
   
   // if( c - localStorage.getItem('render') < 0 ) {
   //    document.getElementById('rem').innerText = 0
   //  } else {
   //    document.getElementById('rem').innerText = c - localStorage.getItem('render');
   //  }

   // var email = res.data.Item.email.S
   var email = localStorage.getItem("email")
   let cn
   
   const payurl = 'https://mxfrnd93z6.execute-api.us-west-2.amazonaws.com/default/read_subscribe_table?email=' + localStorage.getItem('email')

// if no email => user hasnt been logged in yet?
if(localStorage.getItem('email')) {

if(!localStorage.getItem('cn')) {
    axios.get(payurl).then(res => {
    
      console.log('json', res.data)
      // console.log('r', res.data.Item.jsonpath)

      cn = res.data.Item.count.S
      cn = parseInt(cn)

       document.getElementById('prx').style.display = 'block'
      document.getElementById('rem').innerText = cn
      localStorage.setItem('cn', cn)

      if(localStorage.getItem('cn') == '0' ) {
        document.getElementById('upgr').innerText = 'UPGRADE'
        document.getElementById('upgr').style.backgroundColor = 'black'
        document.getElementById('upgr').style.color = 'white'

      }

    }).catch(e => {
      cn = 0

      try {
      document.getElementById('prx').style.display = 'block'
    }catch(e) {
      console.log('e', e)
    }

      try {
      document.getElementById('rem').innerText = cn
    } catch(e) {
      console.log('e', e)
    }

      localStorage.setItem('cn', cn)

       if(localStorage.getItem('cn') == '0' ) {
        document.getElementById('upgr').innerText = 'UPGRADE'
        document.getElementById('upgr').style.backgroundColor = 'black'
        document.getElementById('upgr').style.color = 'white'

      }
    }) 

} else {
  document.getElementById('prx').style.display = 'block'
  document.getElementById('rem').innerText = localStorage.getItem('cn')
   if(localStorage.getItem('cn') == '0' ) {
        document.getElementById('upgr').innerText = 'UPGRADE'
        document.getElementById('upgr').style.backgroundColor = 'black'
        document.getElementById('upgr').style.color = 'white'

      }
}

}

  // // var email = res.data.Item.email.S
  // var email = localStorage.getItem("email")

  
  var geturl = 'https://6exomeey7e.execute-api.us-west-2.amazonaws.com/default/read_CRUD_table?email=' + email;

console.log('geturl', geturl)

   axios.get(geturl).then(res => {
    console.log('res json', res)
    console.log('json', res.data)
   // console.log('r', res.data.Item.jsonpath)


   var jsn = res.data.Item.jsonpath.S


   console.log(jsn)

   var v = jsn.split(',')


  if(jsn == '') v = []
   // t[i].length - 2

    document.getElementById("circleDown").style.display = 'block';

   for (var i=v.length-1; i>=0; i--) {


      var pa = document.getElementById("rx") ;

      var video = document.createElement("video");
      
      // var s = n[0] + '#t=' + t1.toString() + ',' + t2.toString()
      video.setAttribute("src", 'https://d2wxuz9jomxjzh.cloudfront.net/' + v[i]); 
      video.setAttribute("x", i); 
      // video.controls = true;
 
      pa.appendChild(video)

     // video.style.width = '25vw';
     // video.style.height = 'auto';
     video.style.padding = '20px';
     video.style.border = '1px dashed red'
     video.muted = true;

   video.addEventListener("click", (e) => {
       e.preventDefault();
       e.stopPropagation();

       e.target.pause()
       
      
        showVideo(e)
   })

   }  //end for

   document.getElementById("circleDown").style.display = 'none'

   }).catch(err => {
   
   try {
   document.getElementById("circleDown").style.display = 'none'
 } catch(e) {
  console.log(e)
 }

 console.log(err)

  })



}